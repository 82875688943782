import createProject from '@/pages/styleAI/home/createProject.vue';
import createAutoProject from '@/pages/styleAI/home/createAutoProject.vue';
import createProposalProject from '@/pages/styleAI/home/createProposalProject.vue';
export const routes = [{
        path: '/',
        name: 'layout',
        component: () => import('@/layout/Layout.vue'),
        redirect: '/wh/login',
        children: [{
                path: '/styleAI',
                name: 'styleAI',
                component: () => import('@/pages/styleAI/Index.vue'),
                redirect: '/styleAI/home',
                meta: {
                    title: '风范AI',
                    activeKey: 'wind', // head选中标识
                    isBeta: false
                },
                children: [{
                        path: '/styleAI/home',
                        name: 'Home',
                        component: () => import('@/pages/styleAI/home/Home.vue'),
                        meta: {
                            title: '风范首页',
                            parentActiveKey: 'wind',
                            iconActive: 'shouyexuanzhong',
                            icon: 'shouyemoren',
                            leftMenu: true //左侧菜单标识
                        },
                    },
                    {
                        path: '/styleAI/create',
                        name: 'createProject',
                        // component: () => import('@/pages/styleAI/home/createProject.vue'),
                        component: createProject,
                        meta: {
                            title: '创建一键可研项目',
                            isShowLeftMenu: 'no', // 是否展示左侧菜单
                            parentActiveKey: 'wind',
                        },
                    },
                    {
                        path: '/styleAI/createAuto',
                        name: 'createAutoProject',
                        // component: () => import('@/pages/styleAI/home/createAutoProject.vue'),
                        component: createAutoProject,
                        meta: {
                            title: '创建AI找风资源项目',
                            isShowLeftMenu: 'no', // 是否展示左侧菜单
                            parentActiveKey: 'wind',
                        },
                    },
                    {
                        path: '/styleAI/createProposal',
                        name: 'createProposalProject',
                        // component: () => import('@/pages/styleAI/home/createAutoProject.vue'),
                        component: createProposalProject,
                        meta: {
                            title: '创建风电项目建议书',
                            isShowLeftMenu: 'no', // 是否展示左侧菜单
                            parentActiveKey: 'wind',
                        },
                    },
                    {
                        path: '/styleAI/project',
                        name: 'project',
                        component: () => import('@/pages/styleAI/project/Project.vue'),
                        meta: {
                            title: '我的项目',
                            leftMenu: true,
                            iconActive: 'wodexiangmuxuanzhong',
                            icon: 'wodexiangmumoren',
                            parentActiveKey: 'wind',
                        },
                    },
                    {
                        path: '/styleAI/draft',
                        name: 'draftLists',
                        component: () => import('@/pages/styleAI/project/draftProject/DraftList.vue'),
                        meta: {
                            title: '项目草稿',
                            leftMenu: true,
                            iconActive: 'xiangmucaogaoxuanzhong',
                            icon: 'xiangmucaogaomoren',
                            parentActiveKey: 'wind',
                        },
                    },
                    {
                        path: '/historical',
                        name: 'historicalVersion',
                        component: () => import('@/pages/styleAI/project/historyList/HistoricalVersion.vue'),
                        meta: {
                            title: '一键可研历史版本',
                            leftActive: '/styleAI/project',
                            parentActiveKey: 'wind',
                        },
                    },
                    {
                        path: '/autoHistorical',
                        name: 'autoHistoricalVersion',
                        component: () => import('@/pages/styleAI/project/historyList/AutoHistoricalVersion.vue'),
                        meta: {
                            title: 'AI找风资源历史版本',
                            leftActive: '/styleAI/project',
                            parentActiveKey: 'wind',
                        },
                    },
                    {
                        path: '/proposalHistorical',
                        name: 'proposalHistoricalVersion',
                        component: () => import('@/pages/styleAI/project/historyList/ProposalVersion.vue'),
                        meta: {
                            title: '项目建议书历史版本',
                            leftActive: '/styleAI/project',
                            parentActiveKey: 'wind',
                        },
                    },
                    {
                        path: '/reportResult',
                        name: 'reportResult',
                        component: () => import('@/pages/resultReport/ReportResult.vue'),
                        meta: {
                            title: '项目结果页',
                            leftActive: '/styleAI/project',
                            parentActiveKey: 'wind',
                        },
                    },
                    {
                        path: '/reportResultNew',
                        name: 'reportResultNew',
                        component: () => import('@/pages/resultReport/ReportResultNew.vue'),
                        meta: {
                            title: '项目结果页',
                        },
                    },
                    {
                        path: '/autoResult',
                        name: 'autoResult',
                        component: () => import('@/pages/resultReport/AutoResult.vue'),
                        meta: {
                            title: 'AI找风资源项目结果页',
                            leftActive: '/styleAI/project',
                            parentActiveKey: 'wind',
                        },
                    },
                    {
                        path: '/resourceMap',
                        name: 'resourceMap',
                        component: () => import('@/pages/resourceMap/ResourceMap.vue'),
                        meta: {
                            title: '项目生成中',
                        },
                    },
                ]
            },
            {
                path: '/pv',
                name: 'pvHome',
                component: () => import('@/pages/photovoltaicAI/Index.vue'),
                meta: {
                    title: '光御AI',
                    activeKey: 'light',
                    disabled: true
                },
                children: [{
                    path: '/pv/home',
                    name: 'pvHome',
                    component: () => import('@/pages/photovoltaicAI/home/Home.vue'),
                    meta: {
                        title: '首页',
                        parentActiveKey: 'pv',
                        leftMenu: true
                    },
                }]
            },
            {
                path: '/myPackage',
                name: 'myPackage',
                component: () => import('@/pages/myPackage/MyPackage.vue'),
                meta: {
                    title: '我的服务',
                    isShowHeadMenu: 'no', // head菜单
                    isShowLeftMenu: 'no', // 是否展示左侧菜单
                },
            },
        ]
    },
    {
        path: '/pdfPreview',
        name: 'pdfPreview',
        component: () => import('@/pages/pdfPreview/pdfPreview.vue'),
        meta: {
            title: '项目预览',
        },
    },
    {
        path: '/autoMapResult',
        name: 'autoMapResult',
        component: () => import('@/pages/styleAI/projectResult/TdMapResult.vue'),
        meta: {
            title: 'AI找风资源项目地图结果页',
        },
    },
    {
        path: '/wh/login',
        name: 'login',
        component: () => import('@/pages/login/Login.vue'),
    },
    {
        path: '/guide',
        name: 'guide',
        component: () => import('@/pages/guide/Guide.vue'),
    }
];