
import { publicService } from '@/api/base';
let promise;
export  async function refreshToken (){
    if(promise) {
        return promise
    }
    promise = new Promise(async(resolve)=> {
        const resp =  await publicService.get('/web/user/refresh-token', {
            refreshToken: sessionStorage.getItem('refreshToken'),
        },{__isRefreshToken:true});        
        sessionStorage.setItem('accessToken',resp.data?.accessToken)
        sessionStorage.setItem('refreshToken',resp.data?.refreshToken)
        resolve(resp.code==200)
    })
    promise.finally(()=>promise = null)
    return promise
}
export function isRefreshRequest(config) {
    return !!config.__isRefreshToken
}