<template>
    <div class="flex-horizontal" v-loading="[checkUpLoading, true]">
        <a-upload
            class="uploadImg"
            list-type="picture-card"
            :accept="props.fileType"
            :disabled="maxFileLength <= fileList.length"
            :showUploadList="false"
            :customRequest="customRequest"
            :before-upload="beforeUpload"
        >
            <div style="margin-top: 8px">
                <iconpark-icon :size="20" name="shangchuantupian"></iconpark-icon>
            </div>
        </a-upload>
        <ul class="file-components">
            <li class="file-item" v-for="(file, fileIndex) in fileList">
                <iconpark-icon
                    class="close-file-item"
                    name="guanbi"
                    @click="delFileItem(fileIndex)"
                ></iconpark-icon>
                <a-image :src="file.url" />
                <!-- <img :src="file.url" alt="" /> -->
            </li>
        </ul>
    </div>
</template>
<style scoped lang="scss">
    @import './uploadImg.scss';
</style>
<script setup>
    import { ref, onMounted } from 'vue';
    import ObsClient from 'esdk-obs-browserjs';
    import { useObsUpload } from '@/components/obsClient/obsUpload';
    import { obsClientInfo } from '@/assets/js/static.js';
    import { getCertificate } from '@/api/modules/base.js';
    let obsClient = null;
    const checkUpLoading = ref(false);

    const getObsClient = async () => {
        await getCertificate().then((res) => {
            if (res && res.data) {
                const infos = {
                    ak: res.data.access,
                    sk: res.data.secret,
                    securityToken: res.data.securityToken,
                    endPoint: obsClientInfo.endPoint,
                };
                sessionStorage.setItem('obsInfo', JSON.stringify(infos));
                obsClient = new ObsClient({
                    access_key_id: res.data.access, // ak
                    secret_access_key: res.data.secret, // sk
                    security_token: res.data.securityToken,
                    server: obsClientInfo.endPoint, // endPoint
                });
            }
        });
    };
    onMounted(() => {
        if (!sessionStorage.getItem('obsInfo')) {
            getObsClient();
        } else {
            console.log(obsClientInfo);
            const infos = JSON.parse(sessionStorage.getItem('obsInfo'));
            if (new Date().getTime() < infos.expiresAt) {
                obsClient = new ObsClient({
                    access_key_id: infos.ak, // ak
                    secret_access_key: infos.sk, // sk
                    security_token: infos.securityToken,
                    server: infos.endPoint, // endPoint
                });
            } else {
                getObsClient();
            }
        }
    });
    let obsUpload = useObsUpload();
    const props = defineProps({
        fileType: {
            deep: true,
            type: String,
            default: '.png,.jpg,.jpge',
        },
        maxFileLength: {
            deep: true,
            type: Number,
            default: 2,
        },
        obsFilePath: {
            deep: true,
            type: String,
            default: '',
        },
    });
    const fileList = ref([]);
    const fileListInfo = ref([]);
    // 上传前验证
    const beforeUpload = async (file) => {
        checkUpLoading.value = true;
        let uploadResult = await obsUpload.uploadFile(file, props.obsFilePath);
        fileList.value.push({
            url: uploadResult.obsPath,
            file: file,
        });
        fileListInfo.value.push({
            fileName: file.name,
            filePath: uploadResult.obsPath,
            suffix: '.' + file.name.split('.')[file.name.split('.').length - 1],
        });
        checkUpLoading.value = false;
    };
    // 删除图片
    const delFileItem = (index) => {
        fileList.value.splice(index, 1);
        fileListInfo.value.splice(index, 1);
    };
    // 自定义上传文件
    const customRequest = (file) => {
        fileListInfo.value.length && resetFileUrl(fileListInfo.value);
    };
    // 获取上传图片的文件信息
    const getData = () => {
        fileListInfo.value.length &&
            fileListInfo.value.map((ele) => {
                const name = decodeURIComponent(ele.filePath.split('?')[0].split('od/')[1]);
                if (ele.filePath) {
                    ele.fileName = name;
                    ele.suffix = name.split('.')[1];
                }
                const path = ele.filePath
                    .split('?')[0]
                    .split('https://whln.obs.cn-north-4.myhuaweicloud.com:443/')[1];
                path && (ele.filePath = path);
            });
        // debugger;
        return fileListInfo.value;
    };
    // 根据项目ID回显图片信息
    const updateFileData = async (data) => {
        if (!obsClient) {
            if (!sessionStorage.getItem('obsInfo')) {
               await getObsClient();
            } else {
                console.log(obsClientInfo);
                const infos = JSON.parse(sessionStorage.getItem('obsInfo'));
                if (new Date().getTime() < infos.expiresAt) {
                    obsClient = new ObsClient({
                        access_key_id: infos.ak, // ak
                        secret_access_key: infos.sk, // sk
                        security_token: infos.securityToken,
                        server: infos.endPoint, // endPoint
                    });
                } else {
                   await getObsClient();
                }
            }
        }
        fileList.value = [];
        fileListInfo.value = [];
        if (data && data.length) {
            const files = ref([]);
            const infos = ref([]);
            for (let index = 0; index < data.length; index++) {
                const ele = data[index];
                const path = ele.filePath;
                const formatPath = decodeURIComponent(path.split('?')[0]);
                const filePath = formatPath.split(obsClientInfo.domain + ':443/')[1];
                obsClient.getObject(
                    {
                        Bucket: obsClientInfo.bucketname,
                        Key: decodeURIComponent(filePath ? filePath : path), // 路径+文件名
                        SaveByType: 'file',
                    },
                    function (err, result) {
                        if (err) {
                            console.error('Error-->' + err);
                        } else {
                            // console.log('Status-->', result.InterfaceResult);
                            if (result.CommonMsg.Status < 300 && result.InterfaceResult) {
                                // 获取下载对象的路径
                                // urlPath.value = result.InterfaceResult.Content.SignedUrl;
                                infos.value.push({
                                    suffix: ele.suff,
                                    fileName: ele.fileName,
                                    filePath: result.InterfaceResult.Content.SignedUrl,
                                });
                                files.value.push({
                                    file: null,
                                    url: result.InterfaceResult.Content.SignedUrl,
                                });
                                if (index === data.length - 1) {
                                    fileList.value = files.value;
                                    fileListInfo.value = infos.value;
                                }
                            } else {
                                // urlPath.value = '';
                            }
                        }
                    },
                );
            }
        }
    };
    // 回显图片路径逻辑处理
    const resetFileUrl = async (fileInfos) => {
        fileListInfo.value = [];
        fileList.value = [];
        if (fileInfos.length) {
            for (let index = 0; index < fileInfos.length; index++) {
                const ele = fileInfos[index];
                const path = ele.filePath;
                const formatPath = decodeURIComponent(path.split('?')[0]);
                const filePath = formatPath.split(
                    ':443/',
                )[1];
                await obsUpload.getFileUrl(decodeURIComponent(filePath ? filePath : path));
                const resultPath = obsUpload.urlPath;
                fileListInfo.value[index] = {};
                fileListInfo.value[index]['filePath'] = resultPath.value
                    .split(':443/')[1]
                    .split('?')[0];
                fileList.value[index] = {};
                fileList.value[index]['url'] = resultPath.value;
            }
        }
    };
    defineExpose({
        getData,
        updateFileData,
    });
</script>
