import {ref} from "vue";
export function useShowBtn() {
    const imgShowBtnIndex = ref(null)
    // 展示图片删除按钮
    const showImgDelteBtn = (val)=> {
        imgShowBtnIndex.value = val
    }
    // 隐藏图片删除按钮
    const hideImgDelteBtn = () => {
        imgShowBtnIndex.value = null
    }
    return {
        imgShowBtnIndex,
        showImgDelteBtn,
        hideImgDelteBtn
    }
}