

const requestConfig = {
    development: {
        publicServiceUrl: 'https://testapi.wh-ge.com/',
        // publicServiceUrl: 'http://172.16.12.99:10000/' // 晓勇本地
    },
    test: {
        publicServiceUrl: 'https://testapi.wh-ge.com/',
    },
    production: {
        publicServiceUrl: 'https://api.wh-ge.com/',
    },
    'k8s-test': {
        publicServiceUrl: 'https://devk8sapi.enableai.cn',
    },
};

export function getEnvUrl() {
    const env = process.env.NODE_ENV;

    console.log('config.ts:28 ==> env-->', env);

    return requestConfig[env];
}
