<template>
    <div class="create-project-template">
        <div
            class="create-project-box"
            :class="isFull ? 'create-project-box full-step' : 'create-project-box'"
        >
            <iconpark-icon
                class="return-back-icon"
                name="fanhui"
                size="24"
                @click="goHome"
            ></iconpark-icon>
            <div class="report-title">《风电电站项目》一键生成可行性研究报告</div>
            <steps>
                <template #header="slotProps">
                    <FirstStep v-show="slotProps.stepCount == 0"></FirstStep>
                    <second-step
                        v-show="slotProps.stepCount == 1"
                        :stepProps="slotProps.stepCount"
                    ></second-step>
                    <ThreeStep v-show="slotProps.stepCount == 2"></ThreeStep>
                </template>
            </steps>
        </div>
        <tdMapNew
            :isResult="false"
            ref="tdMapNewRef"
            :class="isFull ? 'tdMap-template tdMap-full-template' : 'tdMap-template'"
        >
            <div
                class="handle-icon-template"
                @click="(isFull = !isFull), (isMouseOver = false), tdMapNewRef.resizeMapView()"
                @mouseenter="isMouseOver = true"
                @mouseleave="isMouseOver = false"
            >
                <iconpark-icon size="25" name="moren" v-if="!isMouseOver"></iconpark-icon>
                <iconpark-icon
                    size="25"
                    name="hoverzhankai"
                    v-if="isFull && isMouseOver"
                ></iconpark-icon>
                <iconpark-icon
                    size="25"
                    name="hovershouqi"
                    v-if="!isFull && isMouseOver"
                ></iconpark-icon>
            </div>
            <div class="project-map-footer">
                <span>
                    <iconpark-icon size="13" name="cefengta"></iconpark-icon>
                    测风塔
                </span>
                <a-divider type="vertical" />
                <span>
                    <iconpark-icon size="14" name="fengji" style="color: #ffd72b"></iconpark-icon>
                    风机
                </span>
                <a-divider type="vertical" />
                <span>
                    <iconpark-icon size="14" name="shengyazhan"></iconpark-icon>
                    升压站
                </span>
                <a-divider type="vertical" />
                <span>
                    <iconpark-icon name="jieruzhan"></iconpark-icon>
                    接入站
                </span>
            </div>
            <div class="tdmap-control-header">
                <span @click="tdMapNewRef.zoomMap(1)">
                    <iconpark-icon size="14" name="jia"></iconpark-icon>
                </span>
                <span @click="tdMapNewRef.zoomMap(-1)">
                    <iconpark-icon size="14" name="jian"></iconpark-icon>
                </span>
                <span @click="tdMapNewRef.zoomMap()">
                    <iconpark-icon size="14" name="huidaoyuandian"></iconpark-icon>
                </span>
            </div>
        </tdMapNew>
    </div>
</template>

<script setup>
    import { onBeforeMount, onMounted, watchEffect, onBeforeUnmount, ref } from 'vue';
    import secondStep from '@/components/steps/windFarmStep/secondStep/secondStep.vue';
    import steps from '@/components/steps/Steps.vue';
    import ThreeStep from '@/components/steps/windFarmStep/threeStep/ThreeStep.vue';
    import FirstStep from '@/components/steps/windFarmStep/firstStep/FirstStep.vue';
    import { useRouter } from 'vue-router';
    import useUserStore from '@/pinia/modules/login';
    import tdMapNew from '@/components/polyMap/tdMapNew.vue';
    const tdMapNewRef = ref(null);
    const isFull = ref(false);
    const isMouseOver = ref(false);
    const userStore = useUserStore();
    console.log('---userStore--', userStore.firstName);
    const router = useRouter();
    const goHome = () => {
        router.go(-1);
    };
    onBeforeMount(async () => {});
    watchEffect(() => {});
    const handleBeforeUnload = (event) => {
        // 阻止默认行为，以防止页面刷新或跳转
        event.preventDefault();
        event.returnValue = '是否需要取消';
    };
    onMounted(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('unload', handleBeforeUnload);
    });
    onBeforeUnmount(() => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
        window.removeEventListener('unload', handleBeforeUnload);
    });
</script>
<style scoped lang="less">
    .full-step {
        transform: translateX(-80%);
    }
    .create-project-template {
        position: fixed;
        top: 50px;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #fff;
        overflow-y: auto;
    }
    .report-title {
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        color: #363b4d;
        line-height: 28px;
        margin: 40px 0 32px 0;
    }
    .create-project-box {
        width: calc(60% - 40px);
        position: relative;
        transition: all ease 0.2s;
        .return-back-icon {
            position: fixed;
            left: 26px;
            cursor: pointer;
            z-index: 999;
        }
    }
    .tdMap-template {
        position: fixed;
        top: 50px;
        bottom: 0px;
        width: 40%;
        transition: width 0.1s linear;
        right: 0;
        .handle-icon-template {
            position: absolute;
            top: 50%;
            width: 40px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 999;
            cursor: pointer;
        }
    }
    .tdMap-full-template {
        transition: width 0.1s linear;
        width: calc(100vw - 120px);
    }
    .project-map-footer {
        position: fixed;
        right: 24px;
        bottom: 24px;
        background: rgba(0, 0, 0, 0.24);
        z-index: 999;
        color: #fff;
        display: flex;
        align-items: center;
        border-radius: 4px;
        box-shadow: 0px 0px 14px 0px rgba(255, 255, 255, 0.16);
        span {
            display: flex;
            align-items: center;
            padding: 8px 16px;
            iconpark-icon {
                margin-right: 8px;
            }
        }
        :deep(.ant-divider-vertical) {
            border-inline-start-color: rgba(255, 255, 255, 0.3);
            top: 0;
            height: 18px;
        }
    }
    .tdmap-control-header {
        position: fixed;
        right: 24px;
        top: 100px;
        display: flex;
        z-index: 999;
        span {
            flex: none;
            width: 36px;
            height: 32px;
            background: rgba(0, 0, 0, 0.24);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            iconpark-icon {
                margin-right: 0;
            }
            &:hover {
                background: #4F4F4F;
            }
            &:last-child {
                width: 32px;
                margin-left: 16px;
                border-radius: 4px;
            }
            &:first-child {
                border-right: 1px solid rgba(255, 255, 255, 0.28);
                border-radius: 4px 0 0 4px;
            }
            &:nth-child(2) {
                border-radius: 0 4px 4px 0;
            }
        }
    }
</style>
