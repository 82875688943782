import { ref } from 'vue';

const FILE_TYPES = 'doc,docx,pdf';
const LAYING_METHOD_OPTIONS = [
    {
        label: '系统根据建设条件自动选型',
        value: 0,
    },
    {
        label: '架空线路',
        value: 1,
    },
    {
        label: '电缆铺设',
        value: 2,
    },

];
const ICE_THICKNESS_OPTIONS = [
    {
        label: '系统根据场区范围自动计算',
        value: 999,
    },
    {
        label: '0mm',
        value: 0,
    },
    {
        label: '5mm',
        value: 5,
    },
    {
        label: '10mm',
        value: 10,
    },
    {
        label: '15mm',
        value: 15,
    },
    {
        label: '20mm',
        value: 20,
    },
    {
        label: '30mm',
        value: 30,
    },
    {
        label: '40mm',
        value: 40,
    },
];
const POWER_GRID_OPTIONS = [
    {
        label: '系统根据场区范围自动计算',
        value: 0,
    },
    {
        label: 'a',
        value: 1,
    },
    {
        label: 'b',
        value: 2,
    },
    {
        label: 'c',
        value: 3,
    },
    {
        label: 'd',
        value: 4,
    },
    {
        label: 'e',
        value: 5,
    },
];
export { FILE_TYPES, LAYING_METHOD_OPTIONS, ICE_THICKNESS_OPTIONS, POWER_GRID_OPTIONS };
