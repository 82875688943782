import {
    render,
    h,
} from 'vue'
import {
    Spin
} from 'ant-design-vue';
const mount = (el, value) => {
    if (!value || !value[0]) {
        return false;
    }
    el.childNodes.forEach((item) => {
        item.style && (item.style.opacity = '1')
    })
    // 绑定节点下创建子节点

    let parent = el.querySelector('#__LOADING_PARENT__')
    if (!parent) {
        parent = document.createElement('div')
        parent.style.position = 'relative'
        if (value[1]) {
            parent.style.position = 'fixed'
            parent.style.left = '0'
            parent.style.top = '0'
            parent.style.bottom = '0'
            parent.style.right = '0'
            parent.style.zIndex = '1002'
        }
        parent.id = '__LOADING_PARENT__'
        if (el.firstChild) {
            if (value[1]) {
                const app = document.querySelector('#app')
                app.appendChild(parent)
            } else {
                el.insertBefore(parent, el.firstChild)
            }
        } else {
            el.appendChild(parent)
        }
    } else {

    }
    parent.style.opacity = '1'
    const elStyle = window.getComputedStyle(el, null)
    console.log(el.clientWidth);
    const style = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        flexDirection: 'column',
        // left: `-${elStyle.paddingLeft}`,
        left: `0`,
        // width: value[1] ? `100vw` : `${el.clientWidth}px`,
        width: `100vw`,
        height: value[1] ? `100vh` : `${el.clientHeight}px`,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        opacity: 1,
        zIndex: 1002
    }
    render(h(Spin, {
        class: 'directive-loading',
        tip: '',
        size: 'large',
        style
    }), parent)
}
const unmount = (el, value) => {
    el.childNodes.forEach((item) => {
        item.style && (item.style.opacity = '1')
    })
    const loading = document.querySelector('#__LOADING_PARENT__')
    if (!loading) return
    loading.remove();
    // if (value[1]) {
    //     // 整屏loading
    //     const app = document.querySelector('#app')
    //     app.removeChild(loading)
    // } else {
    //     el.removeChild(loading)
    // }
}
export default {
    // 在绑定元素的父组件 及他自己的所有子节点都挂载完成后调用
    mounted: (el, binding) => {
        // [是否显示loading, 是否全局显示]
        binding.value && mount(el, binding.value)
    },
    // 在绑定元素的父组件 及他自己的所有子节点都更新后调用
    updated: (el, binding) => {
        if (binding.value) {
            binding.value[0] ? mount(el, binding.value) : unmount(el, binding.value)
        }
    }
}