<template>
    <div class="fan-classify-components">
        <p class="flex-horizontal" v-for="(exist,existIndex) in existData" :key="existIndex">
            <a-radio-group v-model:value="existType" name="radioGroup">
                <a-radio value="1">有</a-radio>
                <a-radio value="0">暂无</a-radio>
            </a-radio-group>
        </p>
    </div>
    <!-- 风机类型 -->
    <div class="handleComponent" v-if="existType == '1'">
        <div class="handele-content" v-for="(absent, absentIndex) in absentData">
            <div class="flex-horizontal handle-item">
                <span>
                    <label>品牌</label>
                    <a-tooltip :trigger="['focus']" placement="topLeft" overlay-class-name="numeric-input">
                        <template v-if="absent.classify" #title>
                        <span class="numeric-input-title">
                            {{ absent.classify }}
                        </span>
                        </template>
                        <a-input autocomplete="off" placeholder="如：金风" style="width: 120px;" v-model:value="absent.classify" />
                    </a-tooltip>
                </span>
                <span class="float-left">
                    <label>额定功率</label>
                    <a-input-number  autocomplete="off" style="width: 120px;" :controls="false" placeholder="如：6.25" v-model:value="absent.power" @blur="(e)=>changeAbsentPower(e,absentIndex)"/>
                </span>
                <div class="cursor-point-components">
                    <span class="cursor-point" v-show="absentData.length > 1">
                        <iconpark-icon name="shanjian" @click="subAbsent(absentIndex)"></iconpark-icon>
                    </span>
                    <span class="cursor-point" v-show="fanClassifyCountMax">
                        <iconpark-icon name="tianjia" @click="addAbsent()"></iconpark-icon>
                    </span>
                </div>
            </div>
        <div class="upload-error" v-if="absent.errorMsg" style="color:red;">
                    <iconpark-icon
                        name="jinggao"
                        size="14px"
                        class="upload-error-icon"
                    ></iconpark-icon
                    >{{ absent.errorMsg }}
                </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    @import './fanClassify.scss';
</style>
<script setup>
    import { message } from 'ant-design-vue';
// import { emit } from 'process';
import { reactive, ref, computed } from 'vue';
import {
    getSimpleCapacityList
    } from '@/api/modules/firstStep';
const emits = defineEmits(['checkValue'])
    // 是否有风机类型
    const existType = ref('0')
    // 不存在的风机类型数据
    let absentData = ref([{
        classify: '',
        power: '',
        errorMsg:''
    }]);
    // 已存在的风机类型数据
    const existData = reactive([{
        classify: '',
        power: ''
    }]);
    // 容量
    const simpleCapacityList = ref([])
    // 判断用户添加风机类型数量
    const fanClassifyCountMax = computed(() => {
        // 手动填写显示情况下判断
        return (absentData.value.length + existData.length) < 6
    })
    // 风机下拉框常量
    const fanSelectOptions = [
        {
            label: '风机1',
            value: 1,
        },
        {
            label: '风机2',
            value: 2,
        },
    ];
    // 删减已有风机类型
    const subExist = (index) => {
        existData.splice(index,1)
        console.log(existData);
    }
    // 添加已有风机类型
    const addExist = () => {
        existData.push({
            classify: ''
        })
    }
    // 删减手填风机类型
    const subAbsent = (index) => {
        absentData.value.splice(index,1)
        // 手动触发风电类型表单验证
        emits('checkValue',true)
    }
    // 添加手填风机类型
    const addAbsent = () => {
        // let messgFlag = absentData.value.some((m)=> m.errorMsg)
        //     if(messgFlag) {
        //         message.warning('请先调整额定功率，再添加新数据')
        //     }else{
        //         absentData.value.push({
        //             classify: '',
        //             power: ''
        //         })
        //     }
        absentData.value.push({
            classify: '',
            power: ''
        })
    
    }
    // 获取风机类型数据结果
    const getData = () => {
        // console.log(absentData.filter(ele => ele.classify !== '' && ele.power !== ''));
        return absentData.value.filter(ele => ele.classify !== '' && ele.power !== '')
    }
    // 表单调整时候风机数据结果回显
    const updatePointData = (data) => {
        console.log(data);
        existType.value = data ? '1' : '0';
        absentData.value = data;
    }
    const changeAbsentPower= async(e,index)=> {
        if(simpleCapacityList.value < 1) {
            await getCapacityList()
        }
        let  result = simpleCapacityList.value.filter((m)=> m == Number(absentData.value[index]['power']))
        if(result.length < 1) {
            absentData.value[index]['errorMsg'] = `系统暂未收录${e.target.value}MW机型，请调整额定功率或联系平台客服处理`
        }else{
            absentData.value[index]['errorMsg'] = ''
        }
    }
    // 获取容量列表
    const getCapacityList = async()=> {
        try {
            let res = await getSimpleCapacityList()
            if(res.code ==200) {
                simpleCapacityList.value = res.data?res.data: []
            }
        }catch(err) {
            console.log('报错',err);
        }
    }
    defineExpose({
        getData,
        existType,
        updatePointData
    })
</script>
