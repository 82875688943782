import axios from "axios";
import {
    configurationPath,
    PACKAGE_TYPE,
} from '@/assets/js/static.js';
import {
    h
} from 'vue';
import {
    Modal
} from 'ant-design-vue';
import {
    verifySign
} from '@/api/modules/base.js';
// 验证套餐有效性
import {
    checkPackageEffective
} from '@/api/modules/reportIndex.js';
import eventBus from '../utils/eventBus';
const errorMsg = {
    '9001': 'Ukey设备未认证',
    '9002': '未检测到UKey设备插入，请插入UKey设备。',
    '9003': '参数长度有误，超出范围',
    '9004': '验证用户PIN失败',
    '9005': '输入的参数名称pbData有误',
    '9006': 'PSA签名失败',
    '9007': '检测到UKey设备与当前账号未能有效匹配，请重新插入与账号匹配的UKey设备。'
}
let infos = null;
let notInitiateType = 0;
const checkCPU = () => {
    let result = 1;
    const agent = navigator.userAgent.toLowerCase();
    const isMac = function () {
        return /macintosh|mac os x/i.test(navigator.userAgent);
    }();
    if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
        // console("32位");
        result = 1;
    } else if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
        // console("64位");
        result = 2;
    }
    if (isMac) {
        result = 3;
        // console("这是mac系统");
    }
    sessionStorage.setItem('userCpu', result);
    return result;
}
let CPU = sessionStorage.getItem('userCpu') ? sessionStorage.getItem('userCpu') : checkCPU();
if (sessionStorage.getItem('customerInfo')) {
    infos = JSON.parse(sessionStorage.getItem('customerInfo'));
}
const checkEffectiveness = (callback, params, HashData, randomStr) => {
    console.log('近这里面了？');
    if (HashData) {
        // 后端验证HashData与用户是否匹对
        verifySign({
            content: randomStr,
            sign: HashData
        }).then(res => {
            if (res.code == 200) {
                if (res.data) {
                    callback(params);
                } else {
                    // Modal.error({
                    //     title: '安全提示',
                    //     content: h('div', {
                    //         class: 'softdog-modal-content'
                    //     }, [h('p', errorMsg['9007']), h('p', {
                    //         class: 'softdog-modal-description'
                    //     }, ['如有问题可联系您的客户经理：' + infos.name + ' ' + infos.phone,h('div',['或平台客服：', h('span',{ class: 'self-bar' }, '155-6259-8617 / 133-3115-8355')])]), h('span', '点击此处可'), h('a', {
                    //         onClick: () => window.open(configurationPath[CPU])
                    //     }, '下载加密驱动程序')]),
                    // });
                    eventBus.emit('loginResult', false);
                    Modal.error({
                        title: '安全提示',
                        content: h('div', {
                            class: 'softdog-modal-content'
                        }, [h('p', errorMsg['9007']), h('p', {
                            class: 'softdog-modal-description'
                        }, ['如有问题可联系', h('div', ['平台客服：', h('span', {
                            class: 'self-bar'
                        }, '155-6259-8617 / 133-3115-8355')])]), h('span', '点击此处可'), h('a', {
                            onClick: () => window.open(configurationPath[CPU])
                        }, '下载加密驱动程序')]),
                    });
                }
            } else {
                eventBus.emit('loginResult', false);
            }
        }).catch(err => {
            console.log(err);
            // Modal.error({
            //     title: '安全提示',
            //     content: h('div', {
            //         class: 'softdog-modal-content'
            //     }, [h('p', errorMsg['9007']), h('p', {
            //         class: 'softdog-modal-description'
            //     }, ['如有问题可联系您的客户经理：' + infos.name + ' ' + infos.phone,h('div',['或平台客服：', h('span',{ class: 'self-bar' }, '155-6259-8617 / 133-3115-8355')])]), h('span', '点击此处可'), h('a', {
            //         onClick: () => window.open(configurationPath[CPU])
            //     }, '下载加密驱动程序')]),
            // });
            eventBus.emit('loginResult', false);
            Modal.error({
                title: '安全提示',
                content: h('div', {
                    class: 'softdog-modal-content'
                }, [h('p', errorMsg['9007']), h('p', {
                    class: 'softdog-modal-description'
                }, ['如有问题可联系', h('div', ['平台客服：', h('span', {
                    class: 'self-bar'
                }, '155-6259-8617 / 133-3115-8355')])]), h('span', '点击此处可'), h('a', {
                    onClick: () => window.open(configurationPath[CPU])
                }, '下载加密驱动程序')]),
            });
        })
    } else {
        // 开放通道
        callback(params);
    }
}

const checkPackage = async (packageType) => {
    let isTry = false;
    await checkPackageEffective({
        companyId: sessionStorage.getItem('userId'),
        itemType: packageType ?packageType : PACKAGE_TYPE.default, // 字符串类型 1风电可研 2光伏可研
    }).then((res) => {
        if (res.code == 200) {
            isTry = res.data.isTry;
            notInitiateType = res.data.notInitiateType
        }
    })
    return isTry;
}


// let CPU = 1;

// checkCPU();

export default {
    // 在绑定元素的父组件 及他自己的所有子节点都挂载完成后调用
    beforeMount: (el, binding) => {
        if (sessionStorage.getItem('customerInfo')) {
            infos = JSON.parse(sessionStorage.getItem('customerInfo'));
        }
        const {
            callback,
            params,
            packageType,
            isExistPackage
        } = binding.value;        
        const checkEvent = async() => {
            // 如果加密狗没有开放就直接执行callback
            if (sessionStorage.getItem('dogKey') == 0) {
                checkEffectiveness(callback, params);
                return;
            }
            if(isExistPackage && isExistPackage=='false') {
                checkEffectiveness(callback, params);
                return
            }
            // 如果用户是试用套餐也要放行执行callback
            const isTry = await checkPackage(packageType);
            // 用户没有买这个套餐
            if (notInitiateType == 2) {
                Modal.error({
                    title: '服务购买提示',
                    content: h('div', {
                        class: 'softdog-modal-content'
                    }, [h('p', '您未购买此功能的服务，请联系平台客服进行服务购买'), h('p', {
                        class: 'softdog-modal-description'
                    }, ['', h('div', ['如有问题可联系平台客服：', h('span', {
                        class: 'self-bar'
                    }, '155-6259-8617 / 133-3115-8355')])])]),
                });
                return false;
            }
            if (isTry) {
                checkEffectiveness(callback, params);
                return;
            }
            console.log(999999999);
            axios.post('http://127.0.0.1:1300/api/GenRandom', {
                length: 32
            }).then(req => {
                if (req.status == 200) {
                    if (req.data.code == 0) {
                        axios.post('http://127.0.0.1:1300/api/GetECCSignData', {
                            pbData: req.data.data.result
                        }).then(res => {
                            if (res.status == 200) {
                                if (res.data.code == 0) {
                                    // 获取私钥签名成功
                                    checkEffectiveness(callback, params, res.data.data.HashData, req.data.data.result);
                                } else {
                                    // 校验失败，恢复登录按钮状态
                                    eventBus.emit('loginResult', false);
                                    Modal.error({
                                        title: '安全提示',
                                        content: h('div', {
                                            class: 'softdog-modal-content'
                                        }, [h('p', errorMsg[res.data.code]), h('p', {
                                            class: 'softdog-modal-description'
                                        }, ['如有问题可联系', h('div', [h('span', {}, '平台客服：'), h('span', {
                                            class: 'self-bar'
                                        }, '155-6259-8617 / 133-3115-8355')])]), h('span', '点击此处可'), h('a', {
                                            onClick: () => window.open(configurationPath[CPU])
                                        }, '下载加密驱动程序')]),
                                    });
                                    
                                }
                            }
                        }).catch(err => {
                            console.log(err);
                            eventBus.emit('loginResult', false);
                            // UKey设备没有插入
                            // Modal.error({
                            //     title: '安全提示',
                            //     content: h('div', {
                            //         class: 'softdog-modal-content'
                            //     }, [h('p', '未检测到UKey设备驱动程序运行，请打开UKey驱动程序，保持正常运行。'), h('p', {
                            //         class: 'softdog-modal-description'
                            //     }, ['如有问题可联系您的客户经理：' + infos.name + ' ' + infos.phone,h('div',['或平台客服：', h('span',{ class: 'self-bar' }, '155-6259-8617 / 133-3115-8355')])]), h('span', '点击此处可'), h('a', {
                            //         onClick: () => window.open(configurationPath[CPU])
                            //     }, '下载加密驱动程序')]),
                            // });
                            // Modal.error({
                            //     title: '安全提示',
                            //     content: h('div', {
                            //         class: 'softdog-modal-content'
                            //     }, [h('p', '未检测到UKey设备驱动程序运行，请打开UKey驱动程序，保持正常运行。'), h('p', {
                            //         class: 'softdog-modal-description'
                            //     }, ['如有问题可联系',h('div',['平台客服：', h('span',{ class: 'self-bar' }, '155-6259-8617 / 133-3115-8355')])]), h('span', '点击此处可'), h('a', {
                            //         onClick: () => window.open(configurationPath[CPU])
                            //     }, '下载加密驱动程序')]),
                            // });
                            Modal.error({
                                title: '安全提示',
                                content: '错误信息',
                            });
                        })
                    } else {
                        eventBus.emit('loginResult', false);
                        // console.log('================', CPU,  configurationPath[CPU]);
                        // Modal.error({
                        //     title: '安全提示',
                        //     content: h('div', {
                        //         class: 'softdog-modal-content'
                        //     }, [h('p', errorMsg[req.data.code]), h('p', {
                        //         class: 'softdog-modal-description'
                        //     }, ['如有问题可联系您的客户经理：' + infos.name + ' ' + infos.phone,h('div',['或平台客服：', h('span',{ class: 'self-bar' }, '155-6259-8617 / 133-3115-8355')])]), h('span', '点击此处可'), h('a', {
                        //         onClick: () => window.open(configurationPath[CPU])
                        //     }, '下载加密驱动程序')]),
                        // });
                        Modal.error({
                            title: '安全提示',
                            content: h('div', {
                                class: 'softdog-modal-content'
                            }, [h('p', errorMsg[req.data.code]), h('p', {
                                class: 'softdog-modal-description'
                            }, ['如有问题可联系', h('div', [h('span', {}, '平台客服：'), h('span', {
                                class: 'self-bar'
                            }, '155-6259-8617 / 133-3115-8355')])]), h('span', '点击此处可'), h('a', {
                                onClick: () => window.open(configurationPath[CPU])
                            }, '下载加密驱动程序')]),
                        });
                        
                    }
                }
            }).catch(err => {
                console.log(err);
                eventBus.emit('loginResult', false);
                // UKey设备没有插入
                Modal.error({
                    title: '安全提示',
                    content: h('div', {
                        class: 'softdog-modal-content'
                    }, [h('p', '未检测到UKey设备驱动程序运行，请打开UKey驱动程序，保持正常运行。'), h('p', {
                        class: 'softdog-modal-description'
                    }, ['如有问题可联系您的客户经理：' + infos.name + ' ' + infos.phone, h('div', ['或平台客服：', h('span', {
                        class: 'self-bar'
                    }, '155-6259-8617 / 133-3115-8355')])]), h('span', '点击此处可'), h('a', {
                        onClick: () => window.open(configurationPath[CPU])
                    }, '下载加密驱动程序')]),
                });
                
            })
        }
        if (el) {
            el.addEventListener('click', (event) => {
                event.preventDefault();
                event.stopPropagation();
                checkEvent();
            })
        } else {
            checkEvent();
        }
    },
}