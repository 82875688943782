import { publicService } from '@/api/base';
// 校验测风塔文件数据
export async function checkWindTowerFileData(data) {
    return await publicService.post('/web/project/checkWindFileData', data,{__isModifyHeader:true});
}
// 校验半径
export async function checkRadius(data) {
    return await publicService.post('/web/project/checkRadius', data);
}
// 校验气候文件
export async function checkClimateFile(data) {
    return await publicService.post('/web/project/file/check-weather', data);
}