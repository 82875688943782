import { createRouter, createWebHistory } from 'vue-router';
import { routes } from '@/router/config';

const routers = createRouter({
    history: createWebHistory('/'),
    routes: routes,
});
routers.beforeEach((to, from, next) => {
    // console.log(to,'路由周期',from);
    next();
});
export default routers;
