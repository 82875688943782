<template>
    <div class="first-step-component" v-loading="[checkFormLoading, true]">
        <a-form
            ref="formRef"
            name="custom-validation"
            class="frist-step-form"
            :model="formState"
            :rules="rules"
            v-bind="layout"
            :label-col="labelCol"
        >
            <a-form-item label="项目名称" name="projectName">
                <a-input
                    v-model:value="formState.projectName"
                    placeholder="请输入您的项目名称"
                    autocomplete="off"
                    :maxlength="30"
                    show-count
                />
            </a-form-item>
            <a-form-item label="规划装机容量" name="capacityMode">
                <a-radio-group
                    v-model:value="formState.capacityMode"
                    :options="capacityModeOptions"
                    @change="onChangeCapacityMode"
                    style="margin-top: 6px; margin-bottom: 16px"
                />
                <a-form-item
                    label=""
                    name="currentProjectCapacity"
                    v-if="formState.capacityMode == 1"
                >
                    <a-input
                        autocomplete="off"
                        placeholder="请输入规划装机容量"
                        suffix="MW"
                        v-model:value="formState.currentProjectCapacity"
                    />
                </a-form-item>
                <a-form-item
                    label="备选风机"
                    name="spareDeviceNum"
                    v-if="formState.capacityMode == 1"
                >
                    <a-input
                        autocomplete="off"
                        placeholder="请输入备选台数"
                        suffix="台"
                        :min="0"
                        class="spareDeviceNumEle"
                        v-model:value="formState.spareDeviceNum"
                        style="width: calc(100% - 72px); margin-left: 16px; margin-top: -10px"
                    />
                    <div
                        class="tip-content"
                        style="margin-top: 8px; margin-left: -56px; margin-bottom: -34px"
                    >
                        说明: 系统会在最大范围内达到指定规则容量及备选风机台数
                    </div>
                </a-form-item>
            </a-form-item>
            <a-form-item label="场区坐标" name="pointType">
                <a-radio-group v-model:value="formState.pointType" :options="pointOptions" />
            </a-form-item>
            <fieldPoint
                ref="fieldPointRef"
                :pointType="formState.pointType"
                @checkFormValidateFields="checkFormValidateFields"
            ></fieldPoint>
            <a-form-item label="测风塔数据" name="isAnemometerTowerData" class="form-item-margin">
                <a-radio-group
                    v-model:value="formState.isAnemometerTowerData"
                    :class="formState.isAnemometerTowerData == 1 ? 'position-margin' : ''"
                    @change="changeEmptyData('anemometerTowerFile')"
                >
                    <a-radio :value="1">有</a-radio>
                    <a-radio :value="0">暂无</a-radio>
                </a-radio-group>
                <AnemometerTower
                    v-if="formState.isAnemometerTowerData == 1"
                    ref="anemometerTowerFileRef"
                    @upAnemomentTowerFiles="getAnemomentTowerFiles"
                ></AnemometerTower>
            </a-form-item>
            <div class="line-break"></div>
            <!-- 高级参数设置 -->
            <div v-show="showMoreKey">
                <a-form-item label="倾向风机类型" name="fanClassify" class="self-input-width">
                    <fanClassify @checkValue="validateFanClassify()" ref="fanClassifyRef" />
                </a-form-item>
                <a-form-item class="auto-form-item" label="倾向轮毂高度" name="preferHubHeight">
                    <a-select
                        class="special-select"
                        v-model:value="formState.preferHubHeight"
                        placeholder=""
                    >
                        <a-select-option
                            v-for="item in preferHubHeightOptions"
                            :value="item['value']"
                        >
                            {{ item['label'] }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="风机点位" name="fanLocation">
                    <a-radio-group
                        v-model:value="formState.fanLocation"
                        :options="fanLocationOptions"
                    />
                </a-form-item>
                <!-- 批量导入逻辑 -->
                <fanPoint
                    ref="fanPointRef"
                    v-if="formState.fanLocation"
                    :pointType="formState.pointType"
                    :handleDisabled="handleDisabled"
                    @checkFormValidateFields="checkFormValidateFieldsFan"
                    @clearValidateFan="clearValidateFan"
                ></fanPoint>
                <a-form-item label="避让区域" name="isAvoidAreasData">
                    <a-radio-group
                        v-model:value="formState.isAvoidAreasData"
                        @change="changeEmptyData('avoidAreasDataJson')"
                        :class="formState.isAvoidAreasData == 1 ? 'position-margin' : ''"
                    >
                        <a-radio :value="1">有</a-radio>
                        <a-radio :value="0">暂无</a-radio>
                    </a-radio-group>
                    <a-form-item
                        label=""
                        name="avoidAreasDataJson"
                        v-if="formState.isAvoidAreasData == 1"
                    >
                        <uploadFolder
                            ref="avoidAreasReportRef"
                            @getCheckData="(val) => getCheckAvoidData(val, 'avoidAreasDataJson')"
                        ></uploadFolder>
                    </a-form-item>
                </a-form-item>
                <a-form-item label="地形图" name="isTopographicMap" class="form-item-margin">
                    <a-radio-group
                        v-model:value="formState.isTopographicMap"
                        @change="changeEmptyData('topographicMapFile')"
                        :class="formState.isTopographicMap == 1 ? 'position-margin' : ''"
                    >
                        <a-radio :value="1">有</a-radio>
                        <a-radio :value="0">暂无</a-radio>
                    </a-radio-group>
                    <a-form-item
                        label=""
                        name="topographicMapFile"
                        v-if="formState.isTopographicMap == 1"
                        class="input-margin"
                    >
                        <Upload
                            v-model:defaultFiles="formState.topographicMapFile"
                            :fileTypes="'zip,rar'"
                            :fileSize="500"
                            :dataObj="dataObj"
                            ref="topographicMapFileRef"
                            :maxCount="1"
                            fileTypeMessage="请选择正确的文件类型"
                            fileSizeMessage="请选择大小限制范围内的文件进行操作"
                            @upFileList="(val) => getUploadFile(val, 'topographicMapFile')"
                        >
                            <template #tip>
                                <div class="tip"
                                    >目前支持 tif（tif需包括.tif/.tfw/.prj3个文件）、dwg
                                    格式，需压缩成zip或rar格式，<br />最多上传1个zip或rar文件，文件最大500M。如有更大文件请联系客服处理
                                </div>
                            </template>
                        </Upload>
                    </a-form-item>
                </a-form-item>
                <a-form-item label="可布机区域" name="isCanDeployableAreaData">
                    <a-radio-group
                        v-model:value="formState.isCanDeployableAreaData"
                        @change="changeEmptyData('canDeployableAreaDataJson')"
                        :class="formState.isCanDeployableAreaData == 1 ? 'position-margin' : ''"
                    >
                        <a-radio :value="1">有</a-radio>
                        <a-radio :value="0">暂无</a-radio>
                    </a-radio-group>
                    <a-form-item
                        label=""
                        name="canDeployableAreaDataJson"
                        v-if="formState.isCanDeployableAreaData == 1"
                    >
                        <uploadFolder
                            ref="distributedMachineAreaRef"
                            :maxGroupNum="1"
                            :descTip="'支持shp文件，一个shp通常有.shp/.shx/.prj/.dbf（这4个格式必须有）等文件格式组成。最多上传1个shp文件，最大支持50M'"
                            @getCheckData="
                                (val) => getCheckAvoidData(val, 'canDeployableAreaDataJson')
                            "
                        ></uploadFolder>
                    </a-form-item>
                </a-form-item>
            </div>
            <div class="show-more" v-show="!showMoreKey" @click="showMoreKey = true">
                <span>高级参数设置</span>
                <iconpark-icon :size="10" name="shuangjiantoushouqi"></iconpark-icon>
                <div class="show-more-illustrate">
                    <i>!</i>
                    <span>点击这里可进行更详细数据参数设置</span>
                </div>
            </div>
            <div class="retract-more" v-show="showMoreKey" @click="showMoreKey = false">
                <span>收起</span>
                <iconpark-icon
                    :size="10"
                    class="retract-icon"
                    name="shuangjiantoushouqi"
                ></iconpark-icon>
            </div>
            <div class="handle-submit-group">
                <a-button
                    v-softDog="{
                        callback: onSubmitForm,
                        params: 1,
                        packageType: PACKAGE_TYPE.auto,
                    }"
                    v-if="route.query.type !== 'edit'"
                >
                    保存草稿
                </a-button>
                <a-button
                    v-softDog="{
                        callback: onSubmitForm,
                        params: 2,
                        packageType: PACKAGE_TYPE.auto,
                    }"
                    class="step-next"
                >
                    提交
                </a-button>
            </div>
        </a-form>
    </div>
</template>
<script setup>
    import dayjs from 'dayjs';
    import { useDebounceFn } from '@vueuse/core';
    import { reactive, ref, onMounted, watch, h, nextTick } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import fanClassify from '../components/fanClassify/fanClassify.vue';
    import { message, Modal } from 'ant-design-vue';
    import eventBus from '@/utils/eventBus';
    import AnemometerTower from '@/components/upload/AnemometerTower.vue';
    import uploadFolder from '../components/UploadFolder.vue';
    import {
        uniqueIdentification, // 生成唯一标识
        saveFormData, // 保存更新提交表单（新增）
        updateFormData, // 调整保存或者更新表单（调整）
        selectProjectInfoById, //根据项目ID进行信息回显
    } from '@/api/modules/autoStep';
    import { PACKAGE_TYPE, obsClientInfo } from '@/assets/js/static.js';
    import { coverPoints } from '@/api/modules/base.js';
    import fieldPoint from '../components/fieldPoint/fieldPoint.vue';
    import fanPoint from '../components/fanPoint/fanPoint.vue';
    import usefieldFanDataStore from '@/pinia/modules/fieldFanData.js';
    import { useObsUpload } from '@/components/obsClient/obsUpload.js';
    import shp from '@/assets/js/shp.esm.js';
    import Upload from '../../upload/Upload.vue';
    import { cloneDeep } from 'lodash-es';
    import { checkRadius } from '@/api/modules/secondStep';
    import { checkPackageEffective } from '@/api/modules/reportIndex.js';
    import { checkAdjustProjectIsDeductTimesByAuto } from '@/api/modules/project.js';
    const router = useRouter();
    const route = useRoute();

    const obsClient = useObsUpload();
    const fieldFanDataStore = usefieldFanDataStore();

    // 表单REF
    const formRef = ref(null); // 整体表单
    const fieldPointRef = ref(null);
    const fanPointRef = ref(null);
    const avoidAreasReportRef = ref(null);
    const distributedMachineAreaRef = ref(null);
    const anemometerTowerFileRef = ref(null);
    const topographicMapFileRef = ref(null);
    const handlePointRef = ref(null); // 手动填写场区坐标点组件
    const fanClassifyRef = ref(null); // 风机类型填写组件

    const labelCol = { style: { width: '270px', marginRight: '16px' } };
    const preferHubHeightOptions = ref([
        {
            label: '100m',
            value: 100,
        },
        {
            label: '105m',
            value: 105,
        },
        {
            label: '110m',
            value: 110,
        },
        {
            label: '120m',
            value: 120,
        },
        {
            label: '130m',
            value: 130,
        },
        {
            label: '140m',
            value: 140,
        },
    ]);
    // 表单数据变量
    let formState = ref({
        capacityMode: 1, // 1：指定容量 2：最大限度规划
        spareDeviceNum: null, // 备用风机数量
        projectName: '',
        currentProjectCapacity: '', // 本期装机容量
        isAnemometerTowerData: 0, // 测风塔数据
        isAvoidAreasData: 0, // 避让区域
        avoidAreasDataJson: [], // 避让区域数据集
        isTopographicMap: 0, // 地形图
        topographicMapFile: [], // 地形图文件
        isCanDeployableAreaData: 0, // 可布机
        canDeployableAreaDataJson: [], // 可布机数据集
        pointType: 0, // 场区坐标类型 0批量导入 1手动录入
        fanLocation: 0, // 风机点位
        preferHubHeight: 120, // 倾向轮毂高度
        anemometerTowerFile: [], // 测风塔文件
    });
    const pointList = ref([]);
    // 验证表单的loading
    const checkFormLoading = ref(false);
    // obs文件存储路径变量
    const obsFilePath = ref('');
    // 生成项目专属随机码
    const getProjectRandom = () => {
        return dayjs(new Date()).format('YYYYMMDDHHmmss').toString() + createRandom(6);
    };
    const createRandom = (n) => {
        return parseInt(Math.random() * Math.pow(10, n));
    };
    // 客服信息
    const customInfos = ref();
    onMounted(() => {
        if (sessionStorage.getItem('customerInfo')) {
            customInfos.value = JSON.parse(sessionStorage.getItem('customerInfo'));
        }
        if (!!route.query.type) {
            // 项目回显
            getProjectInfoByID(sessionStorage.getItem('projectID'));
        } else {
            // 新增项目创建一个路径地址
            obsFilePath.value =
                sessionStorage.getItem('userName') +
                '/zdbj/pro-' +
                createRandom(6) +
                '/' +
                getProjectRandom() +
                '/od/';
            sessionStorage.setItem('obsFilePath', obsFilePath.value);
            // 新增情况下，直接生成一个新的唯一标识
            fieldFanDataStore.uniqueIdentificationUUID = uniqueIdentification();
        }
    });
    // 验证反馈
    let validateName = async (_rult, value) => {
        if (!value) {
            return Promise.reject('请输入您的项目名称');
        } else {
            if (value.length < 2) {
                return Promise.reject('项目名称最少两个字符');
            } else {
                return Promise.resolve();
            }
        }
    };
    let validateCurrentCapacity = async (_rult, value) => {
        const formateValue = Number(value);
        if (!value || isNaN(formateValue)) {
            formState.value.currentProjectCapacity = '';
            return Promise.reject('请输入规划装机容量');
        } else {
            if (formateValue < 5 || formateValue > 1000) {
                return Promise.reject('规划装机容量范围为5~1000,若您有需要可联系平台客服处理');
            } else {
                formState.value.currentProjectCapacity = formateValue.toFixed(2);
                return Promise.resolve();
            }
        }
    };
    let validateStorageRatio = async (_rule, value) => {
        if (formState.value.energyStorage !== 3 && formState.value.select == null) {
            return Promise.reject('请选择配置容量占本期比例');
        } else {
            return Promise.resolve();
        }
    };
    let validateStoragePrice = async (_rule, value) => {
        if (formState.value.energyStorage == 2 && !formState.value.rentalUnitPrice) {
            return Promise.reject('请输入租借单价');
        } else {
            return Promise.resolve();
        }
    };
    let validateFanClassify = async (_rule, value) => {
        formRef.value.clearValidate(['fanClassify']);
        // 检查风机类型
        if (fanClassifyRef.value.existType * 1 && !fanClassifyRef.value.getData().length) {
            return Promise.reject('请至少输入一组有效的风机类型信息');
        } else {
            let errorKey = false;
            const data = fanClassifyRef.value.getData();
            let messgFlag = data.some((m) => m.errorMsg);
            if (messgFlag) {
                return Promise.reject('');
            }
            data.map((ele) => {
                const filterResult = data.filter(
                    (item) => item.classify === ele.classify && item.power === ele.power,
                );
                if (filterResult.length > 1) {
                    errorKey = true;
                }
            });
            if (errorKey) {
                return Promise.reject('风机类型信息中存在重复项');
            } else {
                let resultStr = '';
                data.map((ele, eleIndex) => {
                    resultStr += ele.classify + ',' + ele.power;
                    if (eleIndex !== data.length - 1) {
                        resultStr += ';';
                    }
                });
                formState.value.preferDeviceCodes = resultStr;
                return Promise.resolve();
            }
        }
    };
    // 风机点位文件验证
    let validateFanLocation = async (_rule, value) => {
        if (formState.value.fanLocation) {
            if (fieldFanDataStore.fanFile && fieldFanDataStore.fanFile.length > 0) {
                return Promise.resolve();
            } else {
                return Promise.reject('请上传风机点位文件！');
            }
        } else {
            return Promise.resolve();
        }
    };
    // 场区坐标文件验证
    let validatePointType = async (_rule, value) => {
        if (formState.value.pointType == 0) {
            if (fieldFanDataStore.pointFile && fieldFanDataStore.pointFile.length > 0) {
                return Promise.resolve();
            } else {
                return Promise.reject('请上传场区坐标文件！');
            }
        } else {
            if (
                fieldPointRef.value.handlePointRef.getData() &&
                fieldPointRef.value.handlePointRef.getData().length > 0
            ) {
                return Promise.resolve();
            } else {
                // return Promise.reject('请录入场区拐点坐标！');
                return Promise.reject('');
            }
        }
    };
    // 验证避让区域文件
    const checkAvoidAreasFilePath = (rule, value, callback) => {
        if (value.length > 0) {
            return new Promise((resolve, reject) => {
                let isAllHasPassed = value.some((item) => item.errorMessage);
                if (isAllHasPassed) {
                    return reject('');
                }
                const isAllHasFilePath = value.every((obj) =>
                    obj.files.every((file) => file.filePath),
                );

                if (!isAllHasFilePath) {
                    return reject('请等待文件全部上传成功后再提交');
                } else {
                    return resolve();
                }
            });
        } else {
            return Promise.reject(`${rule.fieldMessage}`);
        }
    };
    // 获取上传文件
    const getUploadFile = async (val, type) => {
        formState.value[type] = [val];
        formRef.value.validateFields([type]);
    };
    // 获取避让区域数据
    const getCheckAvoidData = (val, type) => {
        formState.value[type] = val;
        if (val.length.length) {
            formRef.value.validateFields([type]);
        }
    };
    // 验证地形图文件
    const checkFilePathUpload = (rule, value, callback) => {
        if (value.length > 0) {
            return new Promise((resolve, reject) => {
                let isAllHasPassed = value.some(
                    (item) => item.isPassed && item.isPassed === 'false',
                );
                if (isAllHasPassed) {
                    return reject('');
                }
                let isAllHasFilePath = value.every((item) => item.filePath);
                if (!isAllHasFilePath) {
                    return reject('请等待文件全部上传成功后再提交');
                } else {
                    resolve();
                }
            });
        } else {
            return Promise.reject(`${rule.fieldMessage}`);
        }
    };
    const rules = reactive({
        projectName: [
            {
                required: true,
                validator: validateName,
                trigger: 'blur',
            },
        ],
        // 备选风机数量
        spareDeviceNum: [
            {
                required: false,
                // validate: validateSpareDeviceNum,
                // trigger: 'blur'
            },
        ],
        // 本期规划装机容量
        currentProjectCapacity: [
            {
                required: true,
                validator: validateCurrentCapacity,
                trigger: 'blur',
            },
        ],
        // 测风塔
        isAnemometerTowerData: [
            {
                required: true,
                message: '请选择测风塔数据',
                trigger: 'change',
            },
        ],
        isAvoidAreasData: [
            {
                required: true,
                trigger: ['change'],
                message: '请选择避让区域',
            },
        ],
        avoidAreasDataJson: [
            {
                required: true,
                trigger: ['blur'],
                fieldMessage: '请上传避让区域',
                validator: checkAvoidAreasFilePath,
            },
        ],
        isCanDeployableAreaData: [
            {
                required: true,
                trigger: ['change'],
                message: '请选择可布机区域',
            },
        ],
        canDeployableAreaDataJson: [
            {
                required: true,
                trigger: ['blur'],
                fieldMessage: '请上传可布机区域',
                validator: checkAvoidAreasFilePath,
            },
        ],
        // 地形图
        isTopographicMap: [
            {
                required: true,
                trigger: ['change'],
                message: '请选择地形图',
            },
        ],
        topographicMapFile: [
            {
                required: true,
                trigger: ['blur'],
                fieldMessage: '请上传地形图',
                validator: checkFilePathUpload,
            },
        ],
        // 场区坐标导入类型
        pointType: [
            {
                required: true,
                validator: validatePointType,
                trigger: 'change',
            },
        ],
        // 风机类型
        fanClassify: [
            {
                required: true,
                validator: validateFanClassify,
                trigger: 'change',
            },
        ],
        // 风机点位
        fanLocation: [
            {
                required: true,
                validator: validateFanLocation,
                trigger: 'change',
            },
        ],
        // 配置容量占本期比例
        storageRatio: [
            {
                required: true,
                validator: validateStorageRatio,
                trigger: 'change',
            },
        ],
        // 储能系统租借单价
        rentalUnitPrice: [
            {
                required: true,
                validator: validateStoragePrice,
                trigger: 'blur',
            },
        ],
        // 规划装机容量类型
        capacityMode: [
            {
                required: true,
            },
        ],
        // 倾向轮毂高度
        preferHubHeight: [
            {
                required: true,
            },
        ],
    });
    // 是否显示高级参数
    let showMoreKey = ref(false);
    // 规划装机容量方式
    const capacityModeOptions = [
        {
            label: '指定容量',
            value: 1,
        },
        {
            label: '最大限度规划',
            value: 2,
        },
    ];
    // 场区坐标常量
    const pointOptions = [
        {
            label: '批量导入',
            value: 0,
        },
        {
            label: '手动录入',
            value: 1,
        },
    ];
    // 场区坐标手动录入结果验证，用于控制风机点位上传disabled
    const handleDisabled = ref(false);
    // 场区对应的腾讯地图数据变量
    const tdMapPointData = ref([]);
    // 风机点位常量
    const fanLocationOptions = [
        {
            label: '有',
            value: 1,
        },
        {
            label: '暂无',
            value: 0,
        },
    ];
    // 表单样式变量
    const layout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 14,
        },
    };
    // 切换规划装机容量
    const onChangeCapacityMode = () => {
        if (formState.value.capacityMode == 2) {
            formState.value.currentProjectCapacity = null;
        }
    };
    // 校验测风塔文件
    const checkWindTowerFileIsPass = async () => {
        let result = false;
        try {
            let params = {
                capacityMode: formState.value.capacityMode,
                currentProjectCapacity: formState.value.currentProjectCapacity,
                coordinateList: fieldFanDataStore.pointData,
                cftPointList: pointList.value,
                projectId: sessionStorage.getItem('projectID'),
            };
            let res = await checkRadius(params);
            if (res.code == 200) {
                if (res.data && res.data.length > 0) {
                    // 根据数组里面的下标值，拼接字符串
                    res.data.forEach((item, index) => {
                        formState.value.anemometerTowerFile[item]['msg'] =
                            '该塔位置导致场区计算面积超过系统计算阈值，请删除或联系客服处理';
                    });
                    anemometerTowerFileRef.value.recordData(formState.value.anemometerTowerFile);
                    Modal.warning({
                        title: '温馨提示',
                        content: h('div', { class: 'modal-content-div' }, [
                            h(
                                'p',
                                `存在测风塔位置偏离场区过远，导致场区计算面积超过系统计算阈值，请删除该塔或联系客服处理`,
                            ),
                            h(
                                'p',
                                { class: 'modal-content-desc' },
                                `您的客户经理：${customInfos.value?.name} ${customInfos.value?.phone}`,
                            ),
                            h(
                                'p',
                                { class: 'modal-content-desc' },
                                `平台客服：155-6259-8617 / 133-3115-8355`,
                            ),
                        ]),
                        okText: '去查看测风塔',
                        onOk: () => {
                            anemometerTowerFileRef.value.scrollToSpecificItem(res.data[0]);
                        },
                    });
                    return result;
                } else {
                    return (result = true);
                }
            } else {
                message.error(res.msg);
                return result;
            }
        } catch (err) {
            return result;
        }
    };
    // 自定义验证逻辑
    const customValidate = () => {
        // 手动触发自己封装组件的验证情况，不通过会显示报错信息
        fanClassifyRef.value.getData();
        // 备选风机单独验证
        const formateValue = Number(formState.value.spareDeviceNum);
        if (formState.value.spareDeviceNum < 0 || isNaN(formateValue)) {
            message.error('备选风机数最小值为0！');
            formState.value.spareDeviceNum = null;
            formRef.value.validateFields(['spareDeviceNum']);
            document
                .getElementsByClassName('spareDeviceNumEle')[0]
                .scrollIntoView({ behavior: 'smooth', block: 'center' });
            return false;
        }
        if (formState.value.pointType == 1) {
            if (
                !fieldPointRef.value.handlePointRef.getData() ||
                fieldPointRef.value.handlePointRef.getData().length == 0
            ) {
                message.error('请先完整录入场区坐标');
                formRef.value.validateFields(['pointType']);
                return false;
            }
        } else {
            if (fieldFanDataStore.pointData.length == 0) {
                // message.error('请先上传场区坐标文件');
                formRef.value.validateFields(['pointType']);
                return false;
            }
        }
        return true;
        // handlePointRef.value.getData();
    };
    // 手动提交表单
    const onSubmitForm = useDebounceFn(async (type) => {
        // 存在测风塔数据需要验证测风塔数据情况
        if (formState.value.isAnemometerTowerData && !(await checkWindTowerFileIsPass())) {
            return false;
        }
        formRef.value
            .validate()
            .then(() => {
                // 如果没有通过组件自定义验证，直接拦截掉
                if (!customValidate()) {
                    return false;
                }
                // 表单验证通过,对入参数据进行处理
                formateSubmitFormData(type);
            })
            .catch((err) => {
                // 表单验证没通过
                // formRef.value.scrollToField(err.errorFields[0].name);
                console.log('错误', err);
                setTimeout(() => {
                    const errorDiv = document.getElementsByClassName('ant-form-item-explain-error');
                    errorDiv.length &&
                        errorDiv[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
                }, 50);
            });
    }, 300);
    // 经纬度格式化处理，大地坐标转十进制经纬度
    const formatLatLng = async (data, lngStr, latStr) => {
        const formatData = [];
        const result = [];
        data.length &&
            data.map((ele) => {
                formatData.push({
                    longitude: ele[lngStr] + '',
                    latitude: ele[latStr] + '',
                });
            });

        try {
            const res = await coverPoints({ points: formatData });
            if (res.code == 200) {
                if (res.data && JSON.parse(res.data).length) {
                    JSON.parse(res.data).map((item) => {
                        const objItem = {};
                        objItem[lngStr] = item.longitude;
                        objItem[latStr] = item.latitude;
                        result.push(objItem);
                    });
                    return result;
                }
            } else {
                return [];
            }
        } catch (error) {
            console.log(error);
            return [];
        }
    };
    // 转换避让区域与可布机风机区域
    const changeDataFormat = (data, type) => {
        let result = [];
        if (!data.length > 0) {
            return result;
        }
        result = formState.value[type].map((item) => {
            return {
                fileName: item.baseName,
                files: item.files.map((m) => {
                    return {
                        fileName: m.file.name,
                        filePath: m.filePath,
                        fileSuffix: m.ext,
                    };
                }),
            };
        });
        return result;
    };
    // 提交数据到后台
    const postData = async (type, params) => {
        checkFormLoading.value = true;
        try {
            const data =
                route.query.type == 'edit'
                    ? await updateFormData(params)
                    : await saveFormData(params);
            if (data && data.code == 200) {
                checkFormLoading.value = false;
                message.success('保存成功');
                // 临时存储唯一标识和项目id
                sessionStorage.setItem(
                    'uniqueIdentification',
                    fieldFanDataStore.uniqueIdentificationUUID,
                );
                sessionStorage.setItem('projectID', data.data);
                sessionStorage.setItem(
                    'currentProjectCapacity',
                    formState.value.currentProjectCapacity,
                );
                // 接口保存成功
                if (type == 1) {
                    // 保存成草稿，停留在当前页面(添加草稿标识参数)
                    gtag('event', 'save_draft', {
                        user_info_id: sessionStorage.getItem('userId'),
                        project_info_id: sessionStorage.getItem('projectID'),
                    });
                } else {
                    // 点击提交直接到项目结果页面
                    router.push({ path: '/autoResult' });
                }
            } else {
                if (data && data.code == 400) {
                    Modal.warning({
                        title: '温馨提示',
                        content: h('div', { class: 'modal-content-div' }, [
                            h('p', `${data.msg}`),
                            h(
                                'p',
                                { class: 'modal-content-desc' },
                                `您的客户经理：${customInfos.value?.name} ${customInfos.value?.phone}`,
                            ),
                            h(
                                'p',
                                { class: 'modal-content-desc' },
                                `平台客服：155-6259-8617 / 133-3115-8355`,
                            ),
                        ]),
                        okText: '知道了',
                        onOk: () => {},
                    });
                }
                checkFormLoading.value = false;
            }
        } catch (error) {
            checkFormLoading.value = false;
        }
    };
    // 提交时，表单数据格式整理
    const formateSubmitFormData = async (type) => {
        // 项目名称格式处理，结尾没有工程、项目两个字要拼接“工程”
        const checkProjectName = (name) => {
            if (name.indexOf('项目') > -1 || name.indexOf('工程') > -1) {
                return name;
            } else {
                return name + '工程';
            }
        };
        // 基础没有高级参数设置情况下
        const params = {
            projectName: checkProjectName(formState.value.projectName), // 项目名称
            capacityMode: formState.value.capacityMode, // 容量模式 1：按照指定容量 2：最大限度规则
            currentProjectCapacity: formState.value.currentProjectCapacity, // 本期装机容量
            spareDeviceNum: formState.value.spareDeviceNum ? formState.value.spareDeviceNum * 1 : 0, // 备选风机台数
            isHaveDevicePoints: 0, // 是否存在风机点位文件 0不存在 1存在
            isTopographicMap: formState.value.isTopographicMap, // 是否存在地形图 0不存在 1存在
            topographicMapFile:
                formState.value.isTopographicMap == 1
                    ? JSON.stringify(formState.value.topographicMapFile)
                    : '[]', // 地形文件
            isAvoidAreasData: formState.value.isAvoidAreasData, // 是否存在避让区域 0不存在 1存在
            isCanDeployableAreaData: formState.value.isCanDeployableAreaData, // 是否存在可布机区域 0不存在 1存在
            devicePoints: fanPointRef.value
                ? typeof fanPointRef.value.fanPointFileData === 'string'
                    ? fanPointRef.value.fanPointFileData
                    : JSON.stringify(fanPointRef.value.fanPointFileData)
                : '[]', // 风机点位数据
            deviceWindFilePath: fanPointRef.value
                ? typeof fanPointRef.value.fanFile === 'string'
                    ? fanPointRef.value.fanFile
                    : fanPointRef.value.fanFile.length
                      ? JSON.stringify(fanPointRef.value.fanPointFileInfo)
                      : '[]'
                : '[]', // 风机点位文件
            preferDeviceCodes: '', // 倾向风机
            obsPath: obsFilePath.value, // obs文件存储路径
            preferHubHeight: formState.value.preferHubHeight, // 倾向轮毂高度
            avoidAreasDataJson: '[]',
            canDeployableAreaDataJson: '[]',
            cftPointList: JSON.stringify(pointList.value), // 测风塔解析数据
        };
        // 是否存在风机文件
        params.isHaveDevicePoints = params.deviceWindFilePath == '[]' ? 0 : 1;
        // 场区坐标数据格式处理
        if (formState.value.pointType == 0) {
            // 批量导入情况
            params.coordinate = JSON.stringify(fieldPointRef.value.pointFileData);
            params.regionFilePath = JSON.stringify(fieldPointRef.value.pointFileInfo);
        } else {
            // 手动录入情况
            let pointData = [];
            const res = await formatLatLng(
                fieldPointRef.value.handlePointRef.getData(),
                'lng',
                'lat',
            );
            pointData = res;
            const resultPointData = [];
            pointData &&
                pointData.map((ele) => {
                    resultPointData.push({
                        regionCode: 1,
                        longitude: ele.lng,
                        latitude: ele.lat,
                    });
                });
            params.coordinate = JSON.stringify(resultPointData);
            params.regionFilePath = JSON.stringify([]);
        }
        // 倾向使用的风机类型数据格式处理
        if (fanClassifyRef.value.existType * 1 && fanClassifyRef.value.getData().length) {
            let str = '';
            const data = fanClassifyRef.value.getData();
            data.map((ele, eleIndex) => {
                str += ele.classify + ',' + ele.power;
                if (eleIndex != data.length - 1) {
                    str += ';';
                }
            });
            params.preferDeviceCodes = str;
        }
        // 测风塔
        const regex = /:443\/(.*?)\?/;
        let anemometerTowerFile = formState.value.anemometerTowerFile.map((item) => {
            return {
                fileName: item.fileName,
                filePath: item.filePath,
                children: item?.children.map((child) => {
                    let pathDetail = child.match(regex);
                    return pathDetail && pathDetail[1];
                }),
            };
        });
        params.isAnemometerTowerData = anemometerTowerFile.length ? 1 : 0;
        params.anemometerTowerFile = JSON.stringify(anemometerTowerFile);
        // 避让区域
        const avoidAreasDataJson = changeDataFormat(
            formState.value.avoidAreasDataJson,
            'avoidAreasDataJson',
        );
        // 可布机区域
        const canDeployableAreaDataJson = changeDataFormat(
            formState.value.canDeployableAreaDataJson,
            'canDeployableAreaDataJson',
        );
        params.avoidAreasDataJson = JSON.stringify(avoidAreasDataJson);
        params.canDeployableAreaDataJson = JSON.stringify(canDeployableAreaDataJson);
        // 如果是草稿
        params.isDraft = type == 1;
        // 如果是项目调整(或者草稿编辑)的话，需要传项目ID
        if (route.query.type) {
            // 草稿、编辑 + 用于解决用户在第一步点击保存草稿又点击下一步
            params['baseAdjustProjectId'] = sessionStorage.getItem('projectID');
            params['id'] = sessionStorage.getItem('projectID');
            // 把旧的项目ID存到临时缓存里面
            sessionStorage.setItem('originProjectID', sessionStorage.getItem('projectID'));
        }
        // 将整理好的入参，通过接口传给后台
        // debugger;

        if (route.query.type == 'edit') {
            // 如果是编辑需要验证调整内容是否需要扣减次数
            checkAdjustProjectIsDeductTimesByAutoEvent(params);
            // postData(type, params);
        } else {
            postData(type, params);
        }
    };
    // 手动触发表单场区验证\风机验证
    const checkFormValidateFields = () => {
        formRef.value.validateFields(['pointType']);
    };
    const checkFormValidateFieldsFan = () => {
        formRef.value.validateFields(['fanLocation']);
    };
    // 清除风机表单验证
    const clearValidateFan = () => {
        formRef.value.clearValidate('fanLocation');
    };
    // 验证服务的有效性
    const checkPackage = async () => {
        let isEffective = true;
        await checkPackageEffective({
            companyId: sessionStorage.getItem('userId'),
            itemType: PACKAGE_TYPE.auto, // 字符串类型 1风电可研 2光伏可研
        }).then((res) => {
            if (res.code == 200) {
                sessionStorage.setItem('isInitiate', res.data.isInitiate);
                if (res.data.isInitiate) {
                    // 可以去创建模板
                    isEffective = true;
                } else {
                    if (res.data.notInitiateType) {
                        messageType.value = res.data.notInitiateType;
                        TipsDialogVisible.value = true;
                    }
                    isEffective = false;
                }
            } else {
                // isShowLoading.value = false;
            }
        });
        return isEffective;
    };
    // 验证项目是否需要扣除套餐次数
    const checkAdjustProjectIsDeductTimesByAutoEvent = async (params) => {
        const res = await checkAdjustProjectIsDeductTimesByAuto(params);
        console.log(res);
        if (res.code == 200 && res.data) {
            if (res.data.flag) {
                // 调整项目需要先判断是否需要扣除套餐次数，如果需要扣除，要验证套餐是否有效，有效再去发送数据到后台
                Modal.confirm({
                    title: '套餐提示',
                    content: h(
                        'div',
                        {
                            class: 'softdog-modal-content',
                        },
                        [
                            h(
                                'p',
                                '当前调整项目因达到扣减次数条件将扣减服务1个次数，确定调整该项目么？',
                            ),
                            h(
                                'p',
                                {
                                    style: 'margin-top: 10px',
                                },
                                '满足条件为：',
                            ),
                            h(
                                'p',
                                {
                                    class: 'softdog-modal-description',
                                    style: 'margin-top: 1px',
                                },
                                res.data.remark,
                            ),
                            h(
                                'p',
                                {
                                    class: 'softdog-modal-description',
                                },
                                [
                                    '',
                                    h('div', [
                                        '平台客服：',
                                        h(
                                            'span',
                                            {
                                                class: 'self-bar',
                                            },
                                            '155-6259-8617 / 133-3115-8355',
                                        ),
                                    ]),
                                ],
                            ),
                        ],
                    ),
                    okText: '确定调整',
                    cancelText: '再考虑下',
                    onOk() {
                        // 调整项目需要先判断是否需要扣除套餐次数，如果需要扣除，要验证套餐是否有效，有效再去发送数据到后台
                        checkPackage().then((res) => {
                            if (res) {
                                postData(type, params);
                            }
                        });
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            } else {
                postData(type, params);
            }
        }
    };
    // 判断内容是否为空，显示 showMoreKey
    const isShowContentFn = () => {
        let result;
        let flagArr = [
            'isCanDeployableAreaData',
            'isAvoidAreasData',
            'isEiaReport',
            'isWaterConservationReport',
        ];
        result = flagArr.find((element) => {
            return formState.value[element] == 1;
        });
        showMoreKey.value = result ? true : false;
    };
    const getObsImgPath2 = (path, index, childIndex, type) => {
        // if (type == 'anemometerTowerFile') {
        // 测风塔
        if (path.includes('http')) {
            formState.value.anemometerTowerFile[index].children[childIndex] = path;
            return;
        }
        obsClient
            .getFileUrl(decodeURIComponent(path), sessionStorage.getItem('obsFilePath') || '')
            .then((res) => {
                formState.value.anemometerTowerFile[index].children[childIndex] = res;
            });
        // }
    };
    // 根据项目ID进行回显
    const getProjectInfoByID = async (projectID) => {
        const data = await selectProjectInfoById({ id: projectID });
        if (data && data.code == 200) {
            const infos = data.data;
            sessionStorage.setItem('currentProjectCapacity', infos.currentProjectCapacity);
            pointList.value = JSON.parse(infos.cftPointList);
            formState.value = Object.assign(
                formState.value,
                infos,
                {
                    projectName: infos.projectName,
                    currentProjectCapacity: infos.currentProjectCapacity, // 本期装机容量
                    spareDeviceNum: infos.spareDeviceNum ? infos.spareDeviceNum : null, // 备选风机数量
                    fanLocation: JSON.parse(infos.devicePoints).length ? 1 : 0, // 风机点位单选
                    preferHubHeight: infos.preferHubHeight || 120, // 轮毂高度
                    isAnemometerTowerData: infos.isAnemometerTowerData, // 是否有测风塔
                    isCanDeployableAreaData: infos.isCanDeployableAreaData, // 是否有可布机区域
                    isAvoidAreasData: infos.isAvoidAreasData, // 是否有避让区域
                },
                {},
            );
            console.log(formState.value);

            // obs文件上传路径处理（调整步骤调整第四级路径）
            if (infos.obsPath) {
                const pathArr = infos.obsPath.split('/');
                pathArr[3] = getProjectRandom();
                let str = '';
                pathArr.map((ele, eleIndex) => {
                    str += ele;
                    if (eleIndex != pathArr.length - 1) {
                        str += '/';
                    }
                });
                obsFilePath.value = str;
                sessionStorage.setItem('obsFilePath', obsFilePath.value);
            }

            // 场区坐标点存放到pinia
            fieldFanDataStore.pointData = infos.coordinate ? JSON.parse(infos.coordinate) : [];
            // 往场区坐标组件里面塞数据
            if (fieldPointRef.value) {
                fieldPointRef.value.pointFileData = infos.coordinate
                    ? JSON.parse(infos.coordinate)
                    : [];
                fieldPointRef.value.pointFileList = infos.coordinate
                    ? [
                          {
                              filePath: '',
                              suffix: '',
                              fileName: '',
                          },
                      ]
                    : [];
            }
            // 风机坐标地图回显
            if (JSON.parse(infos.devicePoints).length) {
                eventBus.emit('uploadFan', JSON.parse(infos.devicePoints));
                fanPointRef.value &&
                    (fanPointRef.value.fanPointFileData = JSON.parse(infos.devicePoints));
            } else {
                eventBus.emit('uploadFan', []);
            }

            // 推荐使用的风机类型数据回显
            formState.value.preferDeviceCodes = infos.preferDeviceCodes;
            if (infos.preferDeviceCodes) {
                const data = infos.preferDeviceCodes.split(';');
                const result = [];
                data.map((ele) => {
                    result.push({
                        classify: ele.split(',')[0],
                        power: ele.split(',')[1],
                    });
                });
                fanClassifyRef.value.updatePointData(result);
            }

            // 拿到详情传过来的文件数组，对应模块文件数据回填
            if (infos.projectFileList) {
                // 场区文件回显
                const pointFile = infos.projectFileList.filter((ele) => {
                    return ele.fileType == 10;
                });
                if (pointFile.length > 0) {
                    pointFile.forEach((element) => {
                        element.filePath = decodeURIComponent(element.filePath);
                        element.suffix = element.fileSuffix
                            ? element.fileSuffix
                            : element.fileName.split('.')[1];
                    });
                    fieldFanDataStore.pointFile = pointFile;
                    nextTick(() => {
                        fieldPointRef.value && (fieldPointRef.value.pointFileInfo = pointFile); // 用于组件文件信息提交
                    });
                }

                // 天地图场区数据回显
                let pointsFormat = [];
                if (infos.coordinate && pointFile.length > 0) {
                    formState.value.pointType = 0;
                    const pointDataObj = {};
                    const pointFileOriginData = JSON.parse(infos.coordinate);
                    pointFileOriginData.map((ele) => {
                        if (!pointDataObj[ele.regionCode]) {
                            pointDataObj[ele.regionCode] = [];
                        }
                        pointDataObj[ele.regionCode].push([ele.latitude, ele.longitude]);
                    });
                    tdMapPointData.value = Object.values(pointDataObj);
                    // fanPointFileData.value = JSON.parse(infos.devicePoints).length
                    //     ? JSON.parse(infos.devicePoints)
                    //     : [];
                    eventBus.emit('uploadPoint', Object.values(pointDataObj));
                } else if (infos.coordinate && pointFile.length == 0) {
                    formState.value.pointType = 1;
                    const formateResult = [];
                    JSON.parse(infos.coordinate).map((ele) => {
                        formateResult.push({
                            lng: ele.longitude,
                            lat: ele.latitude,
                        });
                        pointsFormat.push([ele.latitude, ele.longitude]);
                    });
                    nextTick(() => {
                        handlePointRef.value.updatePointData(formateResult);
                    });
                }
                pointFile.length > 0 &&
                    fieldPointRef.value &&
                    fieldPointRef.value.pointLocationRef.updateFileData(pointFile);

                // 风机文件回显
                const fanFile = infos.projectFileList.filter((ele) => {
                    return ele.fileType == 11;
                });
                formState.value.fanLocation = fanFile.length > 0 ? 1 : 0;

                reloadMapData(infos);
                showMoreKey.value = true;
                // isShowContentFn();
                // Todo 回显避让区域
                nextTick(() => {
                    // 地形图文件回显
                    formState.value.topographicMapFile = infos.projectFileList.filter((ele) => {
                        return ele.fileType == 5;
                    });
                    infos.isTopographicMap &&
                        topographicMapFileRef.value.updateFileData(
                            formState.value.topographicMapFile,
                        );
                    // 测风塔文件回显
                    if (formState.value.isAnemometerTowerData) {
                        const anemometerTowerFile = infos.projectFileList.filter((ele) => {
                            return ele.fileType == 3;
                        });
                        formState.value.anemometerTowerFile = anemometerTowerFile;
                        let result2 = cloneDeep(JSON.parse(anemometerTowerFile[0]['filePath']));
                        formState.value.anemometerTowerFile = result2;
                        result2.forEach((item, index) => {
                            if (item.children.length > 0) {
                                item.children.forEach((child, childIndex) => {
                                    getObsImgPath2(child, index, childIndex, 'anemometerTowerFile');
                                });
                            }
                        });
                        nextTick(() => {
                            anemometerTowerFileRef.value.recordData(
                                formState.value.anemometerTowerFile,
                            );
                        });
                    }
                    if (formState.value.isAvoidAreasData) {
                        avoidAreasReportRef.value.loadExistingData(
                            formState.value.avoidAreaFileGroupList,
                        );
                    }
                    if (formState.value.isCanDeployableAreaData) {
                        distributedMachineAreaRef.value.loadExistingData(
                            formState.value.canDeployableAreaGroupList,
                        );
                    }
                    if (fanFile.length > 0 && fanPointRef.value) {
                        fanPointRef.value.fanFile = fanFile;
                        fanPointRef.value.fanLocationRef.updateFileData(fanFile);
                        fanPointRef.value.fanPointFileInfo = fanFile;
                        fieldFanDataStore.fanFile = fanFile;
                        fanPointRef.value.fanPointFileData = infos.devicePoints;
                    }
                });
            }
        }
    };
    // 单选按钮更改时，需要变更数据
    const changeEmptyData = (e) => {
        formState.value[e] = [];
    };
    // 获取测风塔数据
    const getAnemomentTowerFiles = (val) => {
        formState.value.anemometerTowerFile = val.file;
        pointList.value = val.pointList;
        eventBus.emit('uploadTower', pointList.value);
        formRef.value.validateFields(['anemometerTowerFile']);
    };
    // 根据项目回显数据进行天地图坐标渲染(为了显示第二步中坐标数据)
    const reloadMapData = async (infoDatas) => {
        // 变电站位置坐标回显
        if (
            infoDatas.accessSubstationPoints &&
            JSON.parse(infoDatas.accessSubstationPoints).length
        ) {
            const res = JSON.parse(infoDatas.accessSubstationPoints);
            eventBus.emit('uploadSubStation', [{ longitude: res[0], latitude: res[1] }]);
        }
        // 测风塔坐标回显
        if (infoDatas.cftPointList && JSON.parse(infoDatas.cftPointList).length) {
            eventBus.emit('uploadTower', JSON.parse(infoDatas.cftPointList));
        }
        // 避让区域回显
        if (infoDatas.avoidAreaFileGroupList) {
            for (let index = 0; index < infoDatas.avoidAreaFileGroupList.length; index++) {
                const element = infoDatas.avoidAreaFileGroupList[index];
                const subIndex = element.files.findIndex((ele) => ele.fileSuffix == 'shp');
                if (subIndex > -1) {
                    // const res = await obsClient.getFileUrl(element.files[subIndex]['filePath']);
                    try {
                        const rew = await obsClient.copyFile(
                            'shpCopy/' + element.files[subIndex]['filePath'],
                            obsClientInfo.bucketname + '/' + element.files[subIndex]['filePath'],
                        );
                        const path =
                            'https://' +
                            obsClientInfo.publicBucketname +
                            '.obs.cn-north-4.myhuaweicloud.com/shpCopy/' +
                            element.files[subIndex]['filePath'];
                        const geojson = await shp(path);
                        eventBus.emit('uploadAvoid', {
                            data: geojson,
                            name: 'name',
                            type: 'AreaOfAvoidance',
                        });
                        // 经纬度渲染完需要删除copy过的文件
                        const rea = await obsClient.delFile(
                            obsClientInfo.publicBucketname,
                            'shpCopy/' + element.files[subIndex]['filePath'],
                        );
                    } catch (error) {}
                }
            }
        }
        // 可布机区域回显
        if (infoDatas.canDeployableAreaGroupList) {
            for (let index = 0; index < infoDatas.canDeployableAreaGroupList.length; index++) {
                const element = infoDatas.canDeployableAreaGroupList[index];
                const subIndex = element.files.findIndex((ele) => ele.fileSuffix == 'shp');
                if (subIndex > -1) {
                    const objectKey = element.files[subIndex]['filePath'].split('?')[0];
                    // const res = await obsClient.getFileUrl(element.files[subIndex]['filePath']);
                    try {
                        const rew = await obsClient.copyFile(
                            'shpCopy/' + objectKey,
                            obsClientInfo.bucketname + '/' + objectKey,
                        );
                        const path =
                            'https://' +
                            obsClientInfo.publicBucketname +
                            '.obs.cn-north-4.myhuaweicloud.com/shpCopy/' +
                            element.files[subIndex]['filePath'];
                        const geojson = await shp(path);
                        eventBus.emit('uploadAvoid', {
                            data: geojson,
                            name: 'name',
                            type: 'MachineArea',
                        });
                        const rea = await obsClient.delFile(
                            obsClientInfo.publicBucketname,
                            'shpCopy/' + objectKey,
                        );
                    } catch (error) {}
                }
            }
        }
    };
</script>
<style scoped lang="scss">
    @import './AutoStep.scss';
    @import '../components/handlePoint/handlePoint.scss';
</style>
<style>
    .modal-content-div p:first-child {
        margin: 8px 0 12px 0;
    }
    .modal-content-desc {
        margin-bottom: 5px;
        font-size: 12px;
        color: #7c8294;
    }
    .tip-content {
        font-size: 12px;
        color: #7c8294;
    }
</style>
