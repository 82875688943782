<template>
    <div class="handle-point-component">
        <p>
            <span class="handle-point-control">
                <i :class="handlePointCount == 1 ? 'active' : ''" @click="changePointCount(1)">1</i>
                <i :class="handlePointCount == 3 ? 'active' : ''" @click="changePointCount(3)">3</i>
                <i :class="handlePointCount == 4 ? 'active' : ''" @click="changePointCount(4)">4</i>
            </span>
            <span>个拐点</span>
        </p>
        <ul>
            <li v-for="(item, itemIndex) in handlePointData">
                <span>{{ itemIndex + 1 }}</span>
                <a-input-number
                    :step="0.000001"
                    :controls="false"
                    placeholder="请输入经度坐标"
                    @blur="checkLngLat(item.lng, itemIndex)"
                    v-model:value="item.lng"
                    autocomplete="off"
                    string-mode
                />
                <a-input-number
                    :step="0.000001"
                    :controls="false"
                    placeholder="请输入纬度坐标"
                    @blur="checkLngLat(item.lat, itemIndex)"
                    v-model:value="item.lat"
                    autocomplete="off"
                    string-mode
                />
            </li>
        </ul>
        <div class="errorMsg" v-show="errorKey">
            <!-- {{ errorMsg }} -->
            <!-- <p>请输入有效的经纬度范围</p> -->
            <!-- <p>中国的经度范围是73°33′E至135°05′E，纬度范围是3°51′N至53°33′N</p> -->
            <p>{{ errorMsg }}</p>
        </div>
    </div>
</template>
<style scoped lang="scss">
    @import './handlePoint.scss';
</style>
<script setup>
    import { reactive, ref, onMounted } from 'vue';
    const props = defineProps({
        // 手动录入坐标回显（用于编辑）
        pointData: {
            type: Array,
            default: () => {
                return [];
            },
        },
    });
    const emit = defineEmits(['updatePointData']);
    onMounted(() => {
        // 初始化判断是新增还是调整
    });
    // 错误提示变量
    let errorKey = ref(false);
    let errorMsg = ref('');
    // 坐标点数据变量
    let handlePointCount = ref(1);
    let handlePointData = ref([{}]);
    // 切换拐点类型事件
    const changePointCount = (count) => {
        handlePointCount.value = count;
        handlePointData.value = [{}];
        for (let i = 1; i < count; i++) {
            handlePointData.value.push({});
        }
        emit('updatePointData', getData());
    };
    // 验证输入格式是否正确
    const checkLngLat = (value, index) => {
        emit('updatePointData', getData());
    };
    // 获取有效拐点坐标信息
    const getData = () => {
        let result = true;
        handlePointData.value.every((item) => {
            // result =
            //     (item.lng >= 73.33 && item.lng <= 135.05 && item.lat >= 3.51 && item.lat <= 53.33) || (/^\d{8}$/.test(parseInt(item.lng)) && /^\d{6}$/.test(parseInt(item.lat)));
                result =
                (item.lng >= 73.33 && item.lng <= 135.05 && item.lat >= 3.51 && item.lat <= 53.33) || (item.lng >= 20727653.648 && item.lng <= 45503314.048 && item.lat >= 453351.689 && item.lat <= 5935831.830);
            errorKey.value = !result;
            // errorMsg.value =
            //     '请输入有效经纬度(中国的经度范围是73°33′E至135°05′E，纬度范围是3°51′N至53°33′N)';
            errorMsg.value = '经度 / 纬度坐标格式错误，请重新填写';
            return result;
        });
        if (result) {
            return handlePointData.value;
        } else {
            return null;
        }
    };
    // 手动录入坐标回显（用于编辑）
    const updatePointData = (data) => {
        handlePointCount.value = data ? data.length : 1;
        handlePointData.value = data ? data : [];
    }
    defineExpose({
        getData,
        updatePointData
    });
</script>
