<template>
    <div class="second-step" v-loading="[formLoading, true]">
        <a-form
            class="second-step-form"
            ref="formRef"
            :colon="false"
            :model="formState"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :scrollToFirstError="true"
        >
            <a-form-item
                label="拟接入变电站额定电压"
                name="substationRatedVoltage"
                class="form-item-margin"
            >
                <a-radio-group
                    @change="checkCurrentProjectCapacity"
                    v-model:value="formState.substationRatedVoltage"
                >
                    <a-radio :value="1">110kV</a-radio>
                    <a-radio :value="2">220kV</a-radio>
                </a-radio-group>
            </a-form-item>
            <a-form-item label="测风塔数据" name="isAnemometerTowerData" class="form-item-margin">
                <a-radio-group
                    v-model:value="formState.isAnemometerTowerData"
                    :class="formState.isAnemometerTowerData == 1 ? 'position-margin' : ''"
                    @change="changeEmptyData('anemometerTowerFile')"
                >
                    <a-radio :value="1">有</a-radio>
                    <a-radio :value="0">暂无</a-radio>
                </a-radio-group>
                <AnemometerTower
                    v-if="formState.isAnemometerTowerData == 1"
                    ref="anemometerTowerFileRef"
                    @upAnemomentTowerFiles="getAnemomentTowerFiles"
                ></AnemometerTower>
                <!-- <a-form-item
                    label=""
                    name="anemometerTowerFile"
                    v-if="formState.isAnemometerTowerData == 1"
                    class="input-margin"
                >
                    <div class="upload-tower-content">
                        <Upload
                            :disabled="disabled"
                            :isImmediatelyUpload="false"
                            :showUploadList="false"
                            v-model:defaultFiles="formState.anemometerTowerFile"
                            :fileTypes="'txt'"
                            :fileSize="100"
                            :dataObj="dataObj"
                            :maxCount="1"
                            :fileTypeMessage="'请选择正确的文件类型'"
                            fileSizeMessage="请选择大小限制范围内的文件进行操作"
                            @upFileList="getTowerUploadFileList"
                        >
                            <template #tip>
                                <div class="tip"
                                    >可上传多个文件。目前支持NRG导出
                                    txt格式的测风塔数据。1个txt仅含1个测风塔数据，单个文件最大支持100M。
                                    如有其他格式的测风塔数据，可联系客服处理
                                </div>
                            </template>
                        </Upload>
                        <anemometer-tower-upload
                            ref="anemometerTowerFileRef"
                            :filesList="formState.anemometerTowerFile"
                            @upImgLists="uploadTowerImgList"
                            @deleteTowerUploadFileItem="deleteTowerUploadFileItem"
                            @deleteTowerImgItem="deleteTowerImgItem"
                        ></anemometer-tower-upload>
                    </div>
                </a-form-item> -->
            </a-form-item>
            <a-form-item
                label="地质勘查报告"
                name="isGeologicalSurveyReport"
                class="form-item-margin"
            >
                <a-radio-group
                    v-model:value="formState.isGeologicalSurveyReport"
                    @change="changeEmptyData('geologicalReportFile')"
                    :class="
                        formState.isGeologicalSurveyReport == 1
                            ? 'position-margin form-item-margin'
                            : ''
                    "
                >
                    <a-radio :value="1">有</a-radio>
                    <a-radio :value="0">暂无</a-radio>
                </a-radio-group>

                <template v-if="formState.isGeologicalSurveyReport == 1">
                    <a-form-item
                        label="是否本场区"
                        name="geologicalReportType"
                        class="input-margin"
                    >
                        <a-radio-group v-model:value="formState.geologicalReportType">
                            <a-radio :value="1">是本场区</a-radio>
                            <a-radio :value="2">临近场区</a-radio>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item label="" name="geologicalReportFile" class="input-margin">
                        <Upload
                            v-model:defaultFiles="formState.geologicalReportFile"
                            :fileTypes="FILE_TYPES"
                            :fileSize="500"
                            :dataObj="dataObj"
                            :maxCount="1"
                            :fileTypeMessage="'文件类型只支持doc、docx、pdf'"
                            fileSizeMessage="文件最大200M"
                            @upFileList="(val) => getUploadFile(val, 'geologicalReportFile')"
                        >
                            <template #tip>
                                <div class="tip"
                                    >目前支持 doc、docx、pdf 格式<br />
                                    pdf需非拍摄、扫描照片/图片，最多上传1个文件
                                </div>
                            </template>
                        </Upload>
                    </a-form-item>
                </template>
            </a-form-item>
            <a-form-item label="地形图" name="isTopographicMap" class="form-item-margin">
                <a-radio-group
                    v-model:value="formState.isTopographicMap"
                    @change="changeEmptyData('topographicMapFile')"
                    :class="formState.isTopographicMap == 1 ? 'position-margin' : ''"
                >
                    <a-radio :value="1">有</a-radio>
                    <a-radio :value="0">暂无</a-radio>
                </a-radio-group>
                <a-form-item
                    label=""
                    name="topographicMapFile"
                    v-if="formState.isTopographicMap == 1"
                    class="input-margin"
                >
                    <Upload
                        v-model:defaultFiles="formState.topographicMapFile"
                        :fileTypes="'zip,rar'"
                        :fileSize="500"
                        :dataObj="dataObj"
                        :maxCount="1"
                        fileTypeMessage="请选择正确的文件类型"
                        fileSizeMessage="请选择大小限制范围内的文件进行操作"
                        @upFileList="(val) => getUploadFile(val, 'topographicMapFile')"
                    >
                        <template #tip>
                            <div class="tip">
                                目前支持 tif（tif需包括.tif/.tfw/.prj3个文件）、dwg 格式，需压缩成zip、rar格式，<br />最多上传1个zip或rar文件，文件最大500M。如有更大文件请联系客服处理
                                <!-- 目前支持 tif、dwg 压缩的zip格式，<br />
                                最多上传一个文件，文件最大500M。如有更大文件请联系客服处理 -->
                            </div>
                        </template>
                    </Upload>
                </a-form-item>
            </a-form-item>
            <a-form-item label="接入系统报告" name="isSystemReport" class="form-item-margin">
                <a-radio-group
                    v-model:value="formState.isSystemReport"
                    class="position-margin position-margin-bottom"
                    @change="changeEmptyData('systemReportFile')"
                >
                    <a-radio :value="1">有</a-radio>
                    <a-radio :value="0">暂无</a-radio>
                </a-radio-group>
                <a-form-item label="拟接入变电站名称" name="accessSubstationName">
                    <a-input
                        v-model:value="formState.accessSubstationName"
                        placeholder="请输入拟接入变电站名称"
                        class="public-input-large"
                    />
                </a-form-item>
                <a-form-item
                    name="accessSubstationLat"
                    label="拟接入变电站位置"
                    class="form-item-margin-zero"
                >
                    <a-input-group compact>
                        <a-input-number
                            placeholder="请输入经度坐标"
                            :step="0.000001"
                            :controls="false"
                            v-model:value="formState.accessSubstationLng"
                            style="width: 138px"
                            @blur="checkStationLngLat"
                        />
                        <a-input-number
                            placeholder="请输入纬度坐标"
                            :step="0.000001"
                            :controls="false"
                            v-model:value="formState.accessSubstationLat"
                            style="width: 138px"
                            @blur="checkStationLngLat"
                        />
                    </a-input-group>
                </a-form-item>

                <template v-if="formState.isSystemReport == 1">
                    <a-form-item label="" name="systemReportFile">
                        <Upload
                            v-model:defaultFiles="formState.systemReportFile"
                            :fileTypes="FILE_TYPES"
                            :fileSize="200"
                            :dataObj="dataObj"
                            :maxCount="1"
                            fileTypeMessage="文件类型只支持doc、docx、pdf"
                            fileSizeMessage="文件最大200M"
                            @upFileList="(val) => getUploadFile(val, 'systemReportFile')"
                        >
                            <template #tip>
                                <div class="tip"
                                    >目前支持 doc、docx、pdf 格式，<br />
                                    pdf需非拍摄、扫描的纯照片/图片格式，最多上传1个文件，文件最大200M
                                </div>
                            </template>
                        </Upload>
                    </a-form-item>
                </template>
            </a-form-item>
            <a-form-item ref="name" name="isWeatherStationData">
                <template v-slot:label>
                    <div class="custom-bable">
                        <span>气象站相关数据 </span>
                        <a-popover trigger="hover" style="width: 400px">
                            <template #content>
                                <ClimateTip></ClimateTip>
                            </template>
                            <iconpark-icon
                                name="bangzhu"
                                size="14"
                                class="tip-data"
                            ></iconpark-icon>
                        </a-popover>
                    </div>
                </template>
                <a-radio-group
                    v-model:value="formState.isWeatherStationData"
                    @change="changeEmptyData('weatherStationFile')"
                    :class="formState.isWeatherStationData == 1 ? 'position-margin' : ''"
                >
                    <a-radio :value="1">有</a-radio>
                    <a-radio :value="0">暂无</a-radio>
                </a-radio-group>
                <a-form-item
                    label=""
                    name="weatherStationFile"
                    v-if="formState.isWeatherStationData == 1"
                >
                    <div class="custom-content">
                        <div class="custom-btn">
                            <a href="javascript:;">点击这里先</a
                            ><span @click="downTemplate">下载导入模版.xlsx</span>
                        </div>
                        <Upload
                            ref="weatherStationFileRef"
                            v-model:defaultFiles="formState.weatherStationFile"
                            class="weather-file-content"
                            fileTypes="xlsx,xls"
                            :fileSize="200"
                            :dataObj="dataObj"
                            :maxCount="1"
                            fileTypeMessage="文件类型只支持xlsx"
                            fileSizeMessage="文件最大200M"
                            @deleteFile="changeWeatherImgShow"
                            @upFileList="(val) => getUploadFile(val, 'weatherStationFile')"
                        >
                            <template #tip>
                                <div class="tip"
                                    >目前支持 xlsx,xls格式<br />
                                    最多上传1个文件，文件最大200M
                                </div>
                            </template>
                        </Upload>
                    </div>
                </a-form-item>
                <a-form-item
                    label="气象图片"
                    v-if="formState.isWeatherStationData == 1 && weatherStationFileIsPass"
                    class="form-item-margin-zero"
                >
                    <div class="weather-content">
                        <Upload
                            v-model:defaultFiles="formState.weatherStationImageFile"
                            name="pic"
                            :showUploadList="false"
                            class="input-margin-top"
                            fileTypes="jpg,jpeg,png"
                            :fileSize="5"
                            :dataObj="dataObj"
                            :maxCount="1"
                            fileTypeMessage="文件类型只支持jpg,jpeg,png"
                            fileSizeMessage="文件最大5M"
                            @upFileList="(val) => getUploadFile(val, 'weatherStationImageFile')"
                        >
                        </Upload>
                        <ul class="img-list">
                            <li
                                v-for="(item, childrenIndex) in formState.weatherStationImageFile"
                                :key="childrenIndex"
                            >
                                <img :src="item.filePath" alt="" />
                                <iconpark-icon
                                    name="guanbi"
                                    size="14"
                                    class="upload-img-shanchu"
                                    @click="changeEmptyData('weatherStationImageFile')"
                                ></iconpark-icon>
                            </li>
                        </ul>
                    </div>
                    <div class="tip weather-tip"
                        >目前支持jpg、jpeg、png 格式，最多上传1个文件，文件最大5M</div
                    >
                </a-form-item>
            </a-form-item>
            <a-divider />
            <a-flex :justify="'center'" :align="'center'" v-if="!showContent">
                <div class="show-more" v-show="!showContent" @click="changeAdvanced">
                    <span>高级参数设置</span>
                    <iconpark-icon :size="10" name="shuangjiantoushouqi"></iconpark-icon>
                    <div class="show-more-illustrate">
                        <i>!</i>
                        <span>点击这里可进行更详细数据参数设置</span>
                    </div>
                </div>
            </a-flex>

            <template v-if="showContent">
                <a-form-item label="避让区域" name="isAvoidAreasData">
                    <a-radio-group
                        v-model:value="formState.isAvoidAreasData"
                        @change="changeEmptyData('avoidAreasDataJson')"
                        :class="formState.isAvoidAreasData == 1 ? 'position-margin' : ''"
                    >
                        <a-radio :value="1">有</a-radio>
                        <a-radio :value="0">暂无</a-radio>
                    </a-radio-group>
                    <a-form-item
                        label=""
                        name="avoidAreasDataJson"
                        v-if="formState.isAvoidAreasData == 1"
                    >
                        <uploadFolder
                            ref="avoidAreasReportRef"
                            @getCheckData="(val) => getCheckAvoidData(val, 'avoidAreasDataJson')"
                        ></uploadFolder>
                    </a-form-item>
                </a-form-item>
                <a-form-item label="可布机区域" name="isCanDeployableAreaData">
                    <a-radio-group
                        v-model:value="formState.isCanDeployableAreaData"
                        @change="changeEmptyData('canDeployableAreaDataJson')"
                        :class="formState.isCanDeployableAreaData == 1 ? 'position-margin' : ''"
                    >
                        <a-radio :value="1">有</a-radio>
                        <a-radio :value="0">暂无</a-radio>
                    </a-radio-group>
                    <a-form-item
                        label=""
                        name="canDeployableAreaDataJson"
                        v-if="formState.isCanDeployableAreaData == 1"
                    >
                        <uploadFolder
                            ref="distributedMachineAreaRef"
                            :maxGroupNum="1"
                            :descTip="'支持shp文件，一个shp通常有.shp/.shx/.prj/.dbf（这4个格式必须有）等文件格式组成。最多上传1个shp文件，最大支持50M'"
                            @getCheckData="
                                (val) => getCheckAvoidData(val, 'canDeployableAreaDataJson')
                            "
                        ></uploadFolder>
                    </a-form-item>
                </a-form-item>
                <a-form-item class="auto-form-item" label="线路铺设方式" name="cableLayingMethod">
                    <a-select class="special-select" v-model:value="formState.cableLayingMethod">
                        <a-select-option
                            v-for="item in LAYING_METHOD_OPTIONS"
                            :value="item['value']"
                            >{{ item['label'] }}</a-select-option
                        >
                    </a-select>
                </a-form-item>
                <a-form-item class="auto-form-item" label="覆冰厚度" name="iceThickness">
                    <a-select class="special-select" v-model:value="formState.iceThickness">
                        <a-select-option
                            v-for="item in ICE_THICKNESS_OPTIONS"
                            :value="item['value']"
                            >{{ item['label'] }}</a-select-option
                        >
                    </a-select>
                </a-form-item>
                <a-form-item class="auto-form-item" label="电网污秽等级" name="dirtyLevel">
                    <a-select class="special-select" v-model:value="formState.dirtyLevel">
                        <a-select-option
                            v-for="item in POWER_GRID_OPTIONS"
                            :value="item['value']"
                            >{{ item['label'] }}</a-select-option
                        >
                    </a-select>
                </a-form-item>
                <a-form-item label="环评报告" name="isEiaReport" class="form-item-margin">
                    <a-radio-group
                        v-model:value="formState.isEiaReport"
                        @change="changeEmptyData('eiaReportFile')"
                        :class="formState.isEiaReport == 1 ? 'position-margin' : ''"
                    >
                        <a-radio :value="1">有</a-radio>
                        <a-radio :value="0">暂无</a-radio>
                    </a-radio-group>
                    <a-form-item label="" name="eiaReportFile" v-if="formState.isEiaReport == 1">
                        <Upload
                            v-model:defaultFiles="formState.eiaReportFile"
                            :fileTypes="FILE_TYPES"
                            :fileSize="200"
                            :dataObj="dataObj"
                            :maxCount="1"
                            fileTypeMessage="目前支持 doc、docx、pdf 格式"
                            fileSizeMessage="文件最大200M"
                            @upFileList="(val) => getUploadFile(val, 'eiaReportFile')"
                        >
                            <template #tip>
                                <div class="tip"
                                    >目前支持 doc、docx、pdf 格式<br />
                                    pdf需非拍摄、扫描的纯照片/图片格式，最多上传1个文件，文件最大200M
                                </div>
                            </template>
                        </Upload>
                    </a-form-item>
                </a-form-item>
                <a-form-item label="水保报告" name="isWaterConservationReport">
                    <a-radio-group
                        v-model:value="formState.isWaterConservationReport"
                        @change="changeEmptyData('waterConservationReportFile')"
                        :class="formState.isWaterConservationReport == 1 ? 'position-margin' : ''"
                    >
                        <a-radio :value="1">有</a-radio>
                        <a-radio :value="0">暂无</a-radio>
                    </a-radio-group>
                    <a-form-item
                        label=""
                        name="waterConservationReportFile"
                        v-if="formState.isWaterConservationReport == 1"
                    >
                        <Upload
                            v-model:defaultFiles="formState.waterConservationReportFile"
                            :fileTypes="FILE_TYPES"
                            :fileSize="200"
                            :dataObj="dataObj"
                            :maxCount="1"
                            fileTypeMessage="目前支持 doc、docx、pdf 格式"
                            fileSizeMessage="文件最大200M"
                            @upFileList="(val) => getUploadFile(val, 'waterConservationReportFile')"
                        >
                            <template #tip>
                                <div class="tip"
                                    >目前支持 doc、docx、pdf 格式<br />
                                    pdf需非拍摄、扫描的纯照片/图片格式，最多上传1个文件，文件最大200M
                                </div>
                            </template>
                        </Upload>
                    </a-form-item>
                </a-form-item>
            </template>
            <a-flex :justify="'center'" :align="'center'" v-if="showContent">
                <div class="put-away-btn" @click="changeAdvanced"
                    >收起
                    <iconpark-icon
                        name="shuangjiantoushouqi"
                        size="12"
                        class="advanced-icon put-away-icon"
                    ></iconpark-icon>
                </div>
            </a-flex>
            <a-form-item class="form-btn-footer" :wrapperCol="{ style: { width: '100%' } }">
                <!-- <a-button @click="nexThreetStep('draft')" class="save-btn" html-type="submit" v-if="isEditOrAdd"
                    >保存草稿</a-button
                >
                <a-button class="next-step" @click="nexThreetStep('next')" type="primary">下一步</a-button> -->
                <a-button
                    :disabled="disabled"
                    v-softDog="{ callback: nexThreetStep, params: 'draft', packageType:PACKAGE_TYPE.default}"
                    class="save-btn"
                    html-type="submit"
                    v-if="isEditOrAdd"
                    >保存草稿</a-button
                >
                <a-button
                    :disabled="disabled"
                    class="next-step"
                    v-softDog="{ callback: nexThreetStep, params: 'next', packageType:PACKAGE_TYPE.default}"
                    type="primary"
                    >下一步</a-button
                >
            </a-form-item>
        </a-form>
    </div>
</template>

<script setup>
    import { useDebounceFn } from '@vueuse/core';
    import { reactive, ref, toRaw, nextTick, onMounted, watch, h } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import eventBus from '@/utils/eventBus';
    import {
        FILE_TYPES,
        LAYING_METHOD_OPTIONS,
        ICE_THICKNESS_OPTIONS,
        POWER_GRID_OPTIONS,
    } from './secondStep';
    import { useShowBtn } from '../../step';
    import Upload from '@/components/upload/Upload.vue';
    import AnemometerTower from '@/components/upload/AnemometerTower.vue';
    import ClimateTip from '../../components/ClimateTip.vue';
    import { message, Modal } from 'ant-design-vue';
    import {
        checkWindTowerFileData,
        checkRadius,
        checkClimateFile,
    } from '@/api/modules/secondStep';
    import { saveFormData, updateFormData } from '@/api/modules/firstStep';
    import { useObsUpload } from '@/components/obsClient/obsUpload';
    import { selectProjectInfoById } from '@/api/modules/firstStep';
    const { imgShowBtnIndex, showImgDelteBtn, hideImgDelteBtn } = useShowBtn();
    import { cloneDeep } from 'lodash-es';
    import uploadFolder from '../../components/UploadFolder.vue';
    import {templatePath,PACKAGE_TYPE} from '@/assets/js/static.js';
    import { coverPoints } from '@/api/modules/base.js';
    import usefieldFanDataStore from '@/pinia/modules/fieldFanData.js';
    const fieldFanDataStore = usefieldFanDataStore();
    const route = useRoute();
    const router = useRouter();
    const props = defineProps({
        stepProps: {
            type: Number,
        },
    });
    const anemometerTowerFileRef = ref(null);
    const weatherStationFileRef = ref(null);
    const pointList = ref([]);
    const weatherStationFileIsPass = ref(false);
    const obsUpload = useObsUpload();
    const formLoading = ref(false);
    const dataObj = ref({
        id: 1,
        val: 2,
    });
    // 本期容量超过140或者等于100能选择220V，未超过只能选110V
    const currentProjectCapacity = ref(0);
    // 对象字段对应的值
    const detailObj = {
        3: 'anemometerTowerFile',
        4: 'geologicalReportFile',
        5: 'topographicMapFile',
        6: 'systemReportFile',
        7: 'weatherStationFile',
        8: 'eiaReportFile',
        9: 'waterConservationReportFile',
        12: 'weatherStationImageFile',
    };
    const formRef = ref();
    const labelCol = {
        style: {
            width: '270px',
            marginRight: '6px',
        },
    };
    const disabled = ref(false);
    const wrapperCol = {
        style: {
            width: '534px',
        },
    };
    // 客服信息
    const customInfos = ref();
    const isEditOrAdd = ref(route.query.type == 'edit' ? false : true);
    // 展开收起
    const showContent = ref(false);
    const changeAdvanced = () => {
        showContent.value = !showContent.value;
        if (showContent.value) {
            nextTick(() => {
                if (formState.value.isAvoidAreasData) {
                    avoidAreasReportRef.value.loadExistingData(formState.value.avoidAreasDataJson);
                }
                if (formState.value.isCanDeployableAreaData) {
                    distributedMachineAreaRef.value.loadExistingData(
                        formState.value.canDeployableAreaDataJson,
                    );
                }
            });
        }
        console.log('战术数据', formState.value);
    };
    //form表单字段
    const formState = ref({
        substationRatedVoltage: 1,
        isAnemometerTowerData: 0,
        anemometerTowerFile: [],
        isGeologicalSurveyReport: 0,
        geologicalReportType: 1,
        geologicalReportFile: [],
        isTopographicMap: 0,
        topographicMapFile: [],
        isSystemReport: 0,
        accessSubstationName: '',
        accessSubstationLat: '',
        accessSubstationLng: '',
        accessSubstationPoints: [],
        systemReportFile: [],
        isWeatherStationData: 0,
        weatherStationFile: [],
        weatherStationImageFile: [],
        isEiaReport: 0,
        eiaReportFile: [],
        isWaterConservationReport: 0,
        waterConservationReportFile: [],
        isAvoidAreasData: 0, // 避让区域
        avoidAreasDataJson: [], // 避让区域
        isCanDeployableAreaData: 0, // 可布机区域
        canDeployableAreaDataJson: [], // 可布机区域
        cableLayingMethod: 0, //线路铺设方式
        iceThickness: 999, //覆冰厚度
        dirtyLevel: 0, //电网污秽等级
    });
    const waitHandle = [
        // 'anemometerTowerFile',
        'geologicalReportFile',
        'accessSubstationPoints',
        'topographicMapFile',
        'systemReportFile',
        // 'weatherStationFile',
        // 'weatherStationImageFile',
        'eiaReportFile',
        'waterConservationReportFile',
    ];
    const checkFilePathUpload = (rule, value, callback) => {
        if (value.length > 0) {
            return new Promise((resolve, reject) => {
                let isAllHasPassed = value.some(
                    (item) => item.isPassed && item.isPassed === 'false',
                );
                if (isAllHasPassed) {
                    return reject('');
                }
                let isAllHasFilePath = value.every((item) => item.filePath);
                if (!isAllHasFilePath) {
                    return reject('请等待文件全部上传成功后再提交');
                } else {
                    resolve();
                }
            });
        } else {
            return Promise.reject(`${rule.fieldMessage}`);
        }
    };
    const checkAvoidAreasFilePath = (rule, value, callback) => {
        if (value.length > 0) {
            return new Promise((resolve, reject) => {
                let isAllHasPassed = value.some((item) => item.errorMessage);
                if (isAllHasPassed) {
                    return reject('');
                }
                const isAllHasFilePath = value.every((obj) =>
                    obj.files.every((file) => file.filePath),
                );

                if (!isAllHasFilePath) {
                    return reject('请等待文件全部上传成功后再提交');
                } else {
                    return resolve();
                }
            });
        } else {
            return Promise.reject(`${rule.fieldMessage}`);
        }
    };
    const checkLatData = () => {
        if (formState.value.accessSubstationLat && formState.value.accessSubstationLng) {
            const lngIsRight =
                (formState.value.accessSubstationLng <= 135.05 &&
                    formState.value.accessSubstationLng >= 73.33) ||
                (formState.value.accessSubstationLng <= 45503314.048 &&
                    formState.value.accessSubstationLng >= 20727653.648);
            const latIsRight =
                (formState.value.accessSubstationLat * 1 <= 53.33 &&
                    formState.value.accessSubstationLat * 1 >= 3.51) ||
                (formState.value.accessSubstationLat * 1 <= 5935831.83 &&
                    formState.value.accessSubstationLat * 1 >= 453351.689);
            if (lngIsRight && latIsRight) {
                return Promise.resolve();
            } else {
                return Promise.reject('经度纬度坐标格式错误，请重新填写！');
            }
        } else if (!formState.value.accessSubstationLat && !formState.value.accessSubstationLng) {
            return Promise.resolve();
        } else {
            return Promise.reject('请录入变电站经度纬度坐标！');
        }
    };
    // 表单校验规则
    const rules = {
        substationRatedVoltage: [
            {
                required: true,
                message: '请选择拟接入变电站额定电压',
                trigger: 'change',
            },
        ],
        isAnemometerTowerData: [
            {
                required: true,
                message: '请选择测风塔数据',
                trigger: 'change',
            },
        ],
        anemometerTowerFile: [
            {
                required: true,
                trigger: ['blur'],
                fieldMessage: '请上传测风塔文件',
                validator: checkFilePathUpload,
            },
        ],
        isGeologicalSurveyReport: [
            {
                required: true,
                trigger: ['change'],
                message: '请选择地质勘查报告',
            },
        ],
        geologicalReportType: [
            {
                required: true,
                trigger: ['change'],
                message: '请选择是否本场区',
            },
        ],
        geologicalReportFile: [
            {
                required: true,
                trigger: ['blur'],
                fieldMessage: '请上传地址勘察报告',
                validator: checkFilePathUpload,
            },
        ],
        isTopographicMap: [
            {
                required: true,
                trigger: ['change'],
                message: '请选择地形图',
            },
        ],
        topographicMapFile: [
            {
                required: true,
                trigger: ['blur'],
                fieldMessage: '请上传地形图',
                validator: checkFilePathUpload,
            },
        ],
        isSystemReport: [
            {
                required: true,
                trigger: ['change'],
                message: '请选择接入系统报告',
            },
        ],
        accessSubstationName: [
            {
                trigger: ['change'],
                message: '请选择拟接入变电站名称',
            },
        ],
        accessSubstationLat: [
            {
                required: false,
                trigger: ['blur'],
                validator: checkLatData,
            },
        ],
        systemReportFile: [
            {
                required: true,
                trigger: ['blur'],
                fieldMessage: '请上传接入系统报告',
                validator: checkFilePathUpload,
            },
        ],
        isWeatherStationData: [
            {
                required: true,
                trigger: ['change'],
                message: '请选择气象站相关数据',
            },
        ],
        weatherStationFile: [
            {
                required: true,
                trigger: ['blur'],
                fieldMessage: '请上传气象站文件',
                validator: checkFilePathUpload,
            },
        ],
        isEiaReport: [
            {
                required: true,
                trigger: ['change'],
                message: '请选择环评报告',
            },
        ],
        eiaReportFile: [
            {
                required: true,
                trigger: ['blur'],
                fieldMessage: '请上传环评报告',
                validator: checkFilePathUpload,
            },
        ],
        isWaterConservationReport: [
            {
                required: true,
                trigger: ['change'],
                message: '请选择水保报告',
            },
        ],
        waterConservationReportFile: [
            {
                required: true,
                trigger: ['blur'],
                fieldMessage: '请上传水保报告',
                validator: checkFilePathUpload,
            },
        ],
        isAvoidAreasData: [
            {
                required: true,
                trigger: ['change'],
                message: '请选择避让区域',
            },
        ],
        avoidAreasDataJson: [
            {
                required: true,
                trigger: ['blur'],
                fieldMessage: '请上传避让区域',
                validator: checkAvoidAreasFilePath,
            },
        ],
        isCanDeployableAreaData: [
            {
                required: true,
                trigger: ['change'],
                message: '请选择可布机区域',
            },
        ],
        canDeployableAreaDataJson: [
            {
                required: true,
                trigger: ['blur'],
                fieldMessage: '请上传可布机区域',
                validator: checkAvoidAreasFilePath,
            },
        ],
        cableLayingMethod: [
            {
                required: true,
                trigger: ['change'],
                message: '请选择线路铺设方式',
            },
        ],
        iceThickness: [
            {
                required: true,
                trigger: ['change'],
                message: '请选择覆冰厚度',
            },
        ],
        dirtyLevel: [
            {
                required: true,
                trigger: ['change'],
                message: '请选择电网污秽等级',
            },
        ],
    };
    // 避让区域文件上传Ref
    const avoidAreasReportRef = ref(null);
    // 可选风机区域文件上传Ref
    const distributedMachineAreaRef = ref(null);
    const processSelf = ref(0);
    // obs上传文件路径
    const obsFilePath = ref('');

    // 获取测风塔数据
    const getAnemomentTowerFiles = (val) => {
        formState.value.anemometerTowerFile = val.file;
        pointList.value = val.pointList;
        eventBus.emit('uploadTower', pointList.value);
        formRef.value.validateFields(['anemometerTowerFile']);
    };
    // 单选按钮更改时，需要变更数据
    const changeEmptyData = (e) => {
        formState.value[e] = [];
        if (e === 'weatherStationFile') {
            formState.value.weatherStationImageFile = [];
            weatherStationFileIsPass.value = false;
        }
    };
    // 获取上传文件
    const getUploadFile = async (val, type) => {
        console.log('val', val);
        if (type == 'weatherStationImageFile') {
            let result = cloneDeep([val]);
            result.forEach((item, index) => {
                getObsImgPath2(item, index, 'weather');
            });
            formRef.value.validateFields([type]);
            return false;
        }
        if (type == 'weatherStationFile') {
            // 校验文件，联调接口
            let result = await checkWeatherFile(val);
            formState.value[type] = [val];
            if (!result.data) {
                formState.value[type][0]['isPassed'] = result.data ? `${result.data}` : 'false';
                formState.value[type][0]['msg'] = `${result.msg}` || '文件校验失败';
            } else {
                weatherStationFileIsPass.value = true;
            }
            weatherStationFileRef.value.updateFileData(formState.value[type]);
            formRef.value.validateFields([type]);

            // console.log(formState.value[type],'--校验文件---',result);
            return false;
        }
        formState.value[type] = [val];
        formRef.value.validateFields([type]);
    };
    // 验证变电站位置经纬度（渲染到地图）
    const checkStationLngLat = async() => {
        if (formState.value.accessSubstationLng && formState.value.accessSubstationLat) {
            const res = await formatLatLng([{
                lng: formState.value.accessSubstationLng,
                lat: formState.value.accessSubstationLat,
            }], 'lng', 'lat')
            console.log(res);
            res.length && eventBus.emit('uploadSubStation', [{'longitude':res[0]['lng'],'latitude':res[0]['lat']}]);
        } else {
            eventBus.emit('uploadSubStation', []);
        }
    }
    // 校验测风塔文件
    const checkWindTowerFileIsPass = async () => {
        let result = false;
        try {
            let params = {
                capacityMode: 1,
                currentProjectCapacity: sessionStorage.getItem('currentProjectCapacity'),
                coordinateList: fieldFanDataStore.pointData,
                cftPointList: pointList.value,
                projectId: sessionStorage.getItem('projectID'),
            };
            let res = await checkRadius(params);            
            if (res.code == 200) {
                if (res.data && res.data.length > 0) {
                    // 根据数组里面的下标值，拼接字符串
                    res.data.forEach((item, index) => {
                        formState.value.anemometerTowerFile[item]['msg'] =
                            '该塔位置导致场区计算面积超过系统计算阈值，请删除或联系客服处理';
                    });
                    anemometerTowerFileRef.value.recordData(formState.value.anemometerTowerFile);
                    Modal.warning({
                        title: '温馨提示',
                        content: h('div', { class: 'modal-content-div' }, [
                            h(
                                'p',
                                `存在测风塔位置偏离场区过远，导致场区计算面积超过系统计算阈值，请删除该塔或联系客服处理`,
                            ),
                            h(
                                'p',
                                { class: 'modal-content-desc' },
                                `您的客户经理：${customInfos.value?.name} ${customInfos.value?.phone}`,
                            ),
                            h(
                                'p',
                                { class: 'modal-content-desc' },
                                `平台客服：155-6259-8617 / 133-3115-8355`,
                            ),
                        ]),
                        okText: '去查看测风塔',
                        onOk: () => {
                            anemometerTowerFileRef.value.scrollToSpecificItem(res.data[0]);
                        },
                    });
                    return result;
                } else {
                    return (result = true);
                }
            } else {
                message.error(res.msg);
                return (result = false);
            }
        } catch (err) {
            return (result = false);
        }
    };
    // 校验表单数据
    const validateForm = async () => {
        let flag = false;
        return formRef.value
            .validate()
            .then(async () => {
                if(formState.value.isAnemometerTowerData ==1){
                    let result = await checkWindTowerFileIsPass();
                    result ? (flag = true) : (flag = false);
                }else{
                    flag = true
                }
                return flag;
            })
            .catch((error) => {
                // formRef.value.scrollToField(error.errorFields[0].name); // 滚动到对应字段位置
                console.log('error', error);
                setTimeout(() => {
                    const errorDiv = document.getElementsByClassName('ant-form-item-explain-error');
                    errorDiv.length &&
                        errorDiv[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
                }, 20);
                return (flag = false);
            });
    };
    // 保存数据
    const submitFormData = (data, type) => {
        formLoading.value = true;
        saveFormData(data)
            .then((res) => {
                formLoading.value = false;
                if (res.code === 200) {
                    message.success('保存成功');
                    if (type === 'next') {
                        eventBus.emit('validateStep', 3);
                    } else {
                        gtag('event', 'save_draft', {
                            user_info_id: sessionStorage.getItem('userId'),
                            project_info_id: sessionStorage.getItem('projectID'),
                        });
                    }
                }
            })
            .catch((err) => {
                formLoading.value = false;
            });
    };
    // 更新接口
    const updateFormDataSubmit = (data, type) => {
        formLoading.value = true;
        updateFormData(data).then((res) => {
            if (res.code === 200) {
                formLoading.value = false;
                message.success('更新成功');
                if (type === 'next') {
                    eventBus.emit('validateStep', 3);
                }
            } else {
                message.error(res.msg);
            }
        });
    };

    const transformObjToString = (obj) => {
        let newObj = {};
        for (let key in obj) {
            if (waitHandle.includes(key)) {
                newObj[key] = JSON.stringify(obj[key]);
            } else {
                newObj[key] = obj[key];
            }
        }
        delete newObj.weatherStationImageFile;
        delete newObj.accessSubstationLat;
        delete newObj.accessSubstationLng;
        return newObj;
    };
    // 获取项目详情
    const getProjectInfo = async () => {
        let id = sessionStorage.getItem('projectID');
        let result = await selectProjectInfoById({ id });
        if (result.code === 200) {
            console.log(result.data);
            pointList.value = result.data.cftPointList ? JSON.parse(result.data.cftPointList) : [];
            eventBus.emit('uploadTower', pointList.value);
            let {
                substationRatedVoltage,
                isAnemometerTowerData,
                isGeologicalSurveyReport,
                geologicalReportType,
                isTopographicMap,
                isSystemReport,
                accessSubstationName,
                isWeatherStationData,
                isEiaReport,
                isWaterConservationReport,
                id,
                isAvoidAreasData,
                isCanDeployableAreaData,
                cableLayingMethod,
                iceThickness,
                dirtyLevel,
            } = result.data;
            formState.value = {
                substationRatedVoltage: !substationRatedVoltage
                    ? 1
                    : substationRatedVoltage == 110
                      ? 1
                      : 2,
                isAnemometerTowerData: isAnemometerTowerData || 0,
                isGeologicalSurveyReport: isGeologicalSurveyReport || 0,
                geologicalReportType: geologicalReportType || 1,
                isTopographicMap: isTopographicMap || 0,
                isSystemReport: isSystemReport || 0,
                accessSubstationName: accessSubstationName || '',
                isWeatherStationData: isWeatherStationData || 0,
                isEiaReport: isEiaReport || 0,
                isWaterConservationReport: isWaterConservationReport || 0,
                isAvoidAreasData: isAvoidAreasData || 0,
                isCanDeployableAreaData: isCanDeployableAreaData || 0,
                cableLayingMethod: cableLayingMethod || 0,
                iceThickness: iceThickness || 999,
                dirtyLevel: dirtyLevel || 0,
                id,
            };
            for (let key in detailObj) {
                let dataKey = result.data.projectFileList?.filter((item) => item.fileType == key);
                let checkData = dataKey.map((item) => {
                    let { fileName, filePath, fileSuffix: suffix, id, uid, projectId } = item;
                    return {
                        fileName,
                        filePath,
                        suffix,
                        id,
                        uid,
                        projectId,
                    };
                });
                formState.value[detailObj[key]] = dataKey ? checkData : [];
            }
            let points = result.data.accessSubstationPoints
                ? JSON.parse(result.data.accessSubstationPoints)
                : [];
            formState.value.accessSubstationLng = points?.length > 0 ? points[0] : '';
            formState.value.accessSubstationLat = points?.length > 1 ? points[1] : '';
            formState.value.anemometerTowerFile =
                formState.value.anemometerTowerFile.length > 0
                    ? JSON.parse(formState.value.anemometerTowerFile[0]['filePath'])
                    : [];
            formState.value.weatherStationFile.length > 0
                ? (weatherStationFileIsPass.value = true)
                : (weatherStationFileIsPass.value = false);
            let result1 = cloneDeep(formState.value.weatherStationImageFile);
            result1 &&
                result1.forEach((item, index) => {
                    getObsImgPath2(item, index, 'weather');
                });
            let result2 = cloneDeep(formState.value.anemometerTowerFile);
            result2.forEach((item, index) => {
                if (item.children.length > 0) {
                    item.children.forEach((child, childIndex) => {
                        getObsImgPath2(child, index, childIndex, 'anemometerTowerFile');
                    });
                }
            });
            if (formState.value.isAnemometerTowerData) {
                nextTick(() => {
                    anemometerTowerFileRef.value.recordData(formState.value.anemometerTowerFile);
                });
            }
            formState.value.avoidAreasDataJson = result.data.avoidAreaFileGroupList
                ? result.data.avoidAreaFileGroupList
                : [];
            formState.value.canDeployableAreaDataJson = result.data.canDeployableAreaGroupList
                ? result.data.canDeployableAreaGroupList
                : [];
            isShowContentFn();
            // Todo 回显避让区域
            nextTick(() => {
                if (formState.value.isAvoidAreasData) {
                    avoidAreasReportRef.value.loadExistingData(formState.value.avoidAreasDataJson);
                }
                if (formState.value.isCanDeployableAreaData) {
                    distributedMachineAreaRef.value.loadExistingData(
                        formState.value.canDeployableAreaDataJson,
                    );
                }
            });
            // console.log(pointList.value, '详情---', formState.value);
        }
    };
    // 经纬度格式化处理，大地坐标转十进制经纬度
    const formatLatLng = async (data, lngStr, latStr) => {
        const formatData = [];
        const result = [];
        data.length &&
            data.map((ele) => {
                formatData.push({
                    longitude: ele[lngStr] + '',
                    latitude: ele[latStr] + '',
                });
            });
        console.log({ points: formatData });

        try {
            const res = await coverPoints({ points: formatData });
            console.log(res);
            if (res.code == 200) {
                if (res.data && JSON.parse(res.data).length) {
                    JSON.parse(res.data).map((item) => {
                        const objItem = {};
                        objItem[lngStr] = item.longitude;
                        objItem[latStr] = item.latitude;
                        result.push(objItem);
                    });
                    console.log(result, '转完的结果');
                    return result;
                }
            } else {
                return [];
            }
        } catch (error) {
            console.log(error);
            return [];
        }
    };
    // 进入第三步
    const nexThreetStep = useDebounceFn(async (type) => {
        const regex = /:443\/(.*?)\?/;
        let isEdit = route.query.type;
        let result = await validateForm();
        if (!result) return;
        let checkweatherImg =
            formState.value.weatherStationImageFile &&
            formState.value.weatherStationImageFile.length > 0
                ? formState.value.weatherStationImageFile.map((item) => {
                      let pathDetail = item.filePath.match(regex);
                      return {
                          ...item,
                          filePath: pathDetail && pathDetail[1],
                          fileType: '12',
                      };
                  })
                : [];
        formState.value.weatherStationFile.forEach((item) => {
            item.fileType = '7';
        });
        // 如果存在变电站位置需要转换经纬度坐标
        let stationLocationData = [];
        if (formState.value.accessSubstationLat && formState.value.accessSubstationLng) {
            const res = await formatLatLng(
                [
                    {
                        lng: formState.value.accessSubstationLng,
                        lat: formState.value.accessSubstationLat,
                    },
                ],
                'lng',
                'lat',
            );
            stationLocationData = res;
        }
        let data = {
            ...transformObjToString(formState.value),
            accessSubstationPoints:
                formState.value.accessSubstationLat && formState.value.accessSubstationLng
                    ? JSON.stringify(
                          stationLocationData.length
                              ? [stationLocationData[0]['lng'] * 1, stationLocationData[0]['lat'] * 1]
                              : [],
                      )
                    : '',
            step: 2,
            id: sessionStorage.getItem('projectID'),
            weatherStationFile:
                formState.value.isWeatherStationData == 1
                    ? JSON.stringify([formState.value.weatherStationFile[0]])
                    : '[]',
            weatherStationImageFile: JSON.stringify(checkweatherImg),
            obsPath: obsFilePath.value,
            cftPointList: JSON.stringify(pointList.value),
        };
        let anemometerTowerFile = data.anemometerTowerFile.map((item) => {
            return {
                fileName: item.fileName,
                filePath: item.filePath,
                children: item?.children.map((child) => {
                    let pathDetail = child.match(regex);
                    return pathDetail && pathDetail[1];
                }),
            };
        });
        data.anemometerTowerFile = JSON.stringify(anemometerTowerFile);
        console.log('避让区域', formState.value.avoidAreasDataJson);
        // 避让区域
        const avoidAreasDataJson = changeDataFormat(
            formState.value.avoidAreasDataJson,
            'avoidAreasDataJson',
        );
        // 可布机区域
        const canDeployableAreaDataJson = changeDataFormat(
            formState.value.canDeployableAreaDataJson,
            'canDeployableAreaDataJson',
        );
        data.avoidAreasDataJson = JSON.stringify(avoidAreasDataJson);
        data.canDeployableAreaDataJson = JSON.stringify(canDeployableAreaDataJson);
        // 变电站位置经纬度数据转换

        if (isEdit === 'edit') {
            data.projectId = sessionStorage.getItem('projectID');
            delete data.id;
        }
        console.log(formState.value.weatherStationFile, '提交数据', data);
        // return
        if (type === 'next') {
            isEdit == 'edit' ? updateFormDataSubmit(data, 'next') : submitFormData(data, 'next');
        } else {
            submitFormData(data, 'draft');
        }
    });
    watch(
        () => props.stepProps,
        (val) => {
            if (val === 1) {
                obsFilePath.value = sessionStorage.getItem('obsFilePath');
                if (sessionStorage.getItem('currentProjectCapacity')) {
                    currentProjectCapacity.value = sessionStorage.getItem('currentProjectCapacity');
                    formState.value.substationRatedVoltage =
                        currentProjectCapacity.value > 140 ? 2 : 1;
                }
                if (route.query.type) {
                    getProjectInfo();
                }
            }
        },
        { immediate: true },
    );
    const getObsImgPath2 = (path, index, childIndex, type) => {
        if (type == 'anemometerTowerFile') {
            // 测风塔
            if (path.includes('http')) {
                formState.value.anemometerTowerFile[index].children[childIndex] = path;
                return;
            }
            obsUpload
                .getFileUrl(decodeURIComponent(path), sessionStorage.getItem('obsFilePath') || '')
                .then((res) => {
                    formState.value.anemometerTowerFile[index].children[childIndex] = res;
                });
        } else {
            // 气象站图片
            if (path.filePath.includes('http')) {
                formState.value.weatherStationImageFile[index] = {
                    fileName: path.fileName,
                    filePath: path.filePath,
                    suffix: path.suffix || path.fileSuffix,
                };
                return;
            }
            obsUpload
                .getFileUrl(
                    decodeURIComponent(path.filePath),
                    sessionStorage.getItem('obsFilePath') || '',
                )
                .then((res) => {
                    formState.value.weatherStationImageFile[index] = {
                        fileName: path.fileName,
                        filePath: res,
                        suffix: path.suffix || path.fileSuffix,
                    };
                });
        }
    };
    const downTemplate = () => {
        window.open(templatePath['weather']);
    };
    // 检测本期容量140MW临界逻辑
    const checkCurrentProjectCapacity = (val) => {
        if (
            (val.target.value == 1 && currentProjectCapacity.value > 140) ||
            (val.target.value == 2 &&
                currentProjectCapacity.value * 1 !== 100 &&
                currentProjectCapacity.value * 1 <= 140)
        ) {
            Modal.warning({
                title: '温馨提示',
                // content: h('div', {class: 'modal-content-div'}, [h('p', '目前系统支持110kv规划50~140MW容量，220kv规划100MW、140~200MW（不含140），如有问题可联系平台客服处理'),h('p',{class: 'modal-content-desc'}, `您的客户经理：${customInfos.value?.name} ${customInfos.value?.phone}`), h('p',{class: 'modal-content-desc'}, `平台客服：155-6259-8617 / 133-3115-8355`)]),
                content: h('div', { class: 'modal-content-div' }, [
                    h(
                        'p',
                        '目前系统支持110kv规划50~140MW容量，220kv规划100MW、140~200MW（不含140），如有问题可联系平台客服处理',
                    ),
                    h(
                        'p',
                        { class: 'modal-content-desc' },
                        `平台客服：155-6259-8617 / 133-3115-8355`,
                    ),
                ]),
                okText: '知道了',
                onOk: () => {
                    formState.value.substationRatedVoltage = val.target.value == 1 ? 2 : 1;
                },
            });
        }
    };
    // 删除文件之后
    const changeWeatherImgShow = () => {
        weatherStationFileIsPass.value = false;
        formState.value.weatherStationImageFile = [];
        formRef.value.validateFields(['weatherStationFile']);
    };
    // 校验气象文件
    const checkWeatherFile = async (val) => {
        weatherStationFileIsPass.value = false;
        try {
            let params = {
                objectKey: val.filePath,
            };
            let res = await checkClimateFile(params);
            return res;
        } catch (err) {}
    };
    // 获取避让区域数据
    const getCheckAvoidData = (val, type) => {
        formState.value[type] = val;
        if (val.length.length) {
            formRef.value.validateFields([type]);
        }
        console.log('获取避让区域', val);
    };
    // 转换避让区域与可布机风机区域
    const changeDataFormat = (data, type) => {
        let result = [];
        if (!data.length > 0) {
            return result;
        }
        result = formState.value[type].map((item) => {
            return {
                fileName: item.baseName,
                files: item.files.map((m) => {
                    return {
                        fileName: m.file.name,
                        filePath: m.filePath,
                        fileSuffix: m.ext,
                    };
                }),
            };
        });
        return result;
    };
    // 判断内容是否为空，显示 showContent
    const isShowContentFn = () => {
        let result;
        let flagArr = [
            'isCanDeployableAreaData',
            'isAvoidAreasData',
            'isEiaReport',
            'isWaterConservationReport',
        ];
        result = flagArr.find((element) => {
            return formState.value[element] == 1;
        });
        showContent.value = result ? true : false;
    };
    onMounted(() => {
        if (sessionStorage.getItem('customerInfo')) {
            customInfos.value = JSON.parse(sessionStorage.getItem('customerInfo'));
        }
    });
</script>

<style scoped lang="scss">
    @import './second.scss';
</style>
